import { TariffTypeAndCompany, Tariffs } from '@/Interface/tariffs';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilityService } from '@services/utility.service';
import { ToastrService } from 'ngx-toastr';
import { Subject, throwError } from 'rxjs';
import { catchError, debounceTime } from 'rxjs/operators';
import Swal from 'sweetalert2';

export function noWhitespaceOrNumValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value || '';
    const trimmedValue = value.trim();

    // Check for leading whitespace
    const isWhitespace = value.length > 0 && value.trim().length === 0;

    // Check if the value (after trimming leading whitespace) consists only of numbers
    const isOnlyNumbers = /^[0-9]+$/.test(trimmedValue);

    // Combine both validations
    return isWhitespace || isOnlyNumbers ? { 'invalidInput': true } : null;
  };
}

@Component({
  selector: 'app-tariff-type',
  templateUrl: './tariff-type.component.html',
  styleUrls: ['./tariff-type.component.scss']
})
export class TariffTypeComponent implements OnInit {
  public addTariffTypeForm: FormGroup
  public editTariffTypeForm: FormGroup
  public pageSizeControl = new FormControl(10);
  pageSize: number;
  pageToGo: number = 1;
  searchElement = '';
  serialNumberArray: Array<number> = [];
  next_page: number;
  current_page: number;
  previous_page: number;
  total_pages: number;
  total_items: number;
  private latestQueryParams: any = {};
  sortColumn: string = null; 
  sortOrder: 'asc' | 'desc' = null; 
  searchSubject: Subject<string> = new Subject();
  loader : boolean = false

  public pageRecordOptions = [
    { value: '10', label: '10' },
    { value: '25', label: '25' },
    { value: '50', label: '50' },
    { value: '100', label: '100' },
    { value: '200', label: '200' },
  ];

  public tariffList: TariffTypeAndCompany[] = [];
  public selectedId:number

  constructor(  private modalService: NgbModal,
    private _utilityService: UtilityService,
    private toaster: ToastrService,
    private formBuilders: FormBuilder) { }

  ngOnInit(): void {
    this.getTableData();
    this.searchSubject.pipe(debounceTime(300)).subscribe((searchTerm: string) => {
      this.searchElement = searchTerm;
      this.updateQueryParams({ search: this.searchElement });
    });
    this.addTariffTypeForm = this.formBuilders.group({
      type: [null,[Validators.required, noWhitespaceOrNumValidator(),
        Validators.pattern(/^[a-zA-Z0-9\s-]*$/)]]
    });
    this.editTariffTypeForm = this.formBuilders.group({
      id: [null],
      type: [null,[Validators.required, noWhitespaceOrNumValidator(),
        Validators.pattern(/^[a-zA-Z0-9\s-]*$/)]]
    });
  }
  
  resetForm(){
    this.addTariffTypeForm.reset();
    this.editTariffTypeForm.reset();
  }

  openEditPopUp(item: TariffTypeAndCompany, contentupdate: any) {
    this.selectedId = item.id;
    this.editTariffTypeForm.patchValue( {
      id:this.selectedId,
      type:item.name
    })
    this.modalService.open(contentupdate);
  }

  addTariffPopUp(content: any) {
    this.modalService.open(content);
  }

  onAddTariffType():void {
    if(this.addTariffTypeForm.valid)
    {
      const formData:TariffTypeAndCompany = {
        name:this.addTariffTypeForm.value.type.trim()
      }
      this._utilityService.addTariffTypes(formData).pipe(
        catchError(error => {
          if (error.status === 400) {
            this.toaster.error(error.error.message);
            this.resetForm();
          } else {
            this.toaster.error('An error occurred. Please try again later.');
            this.resetForm();
          }
          return throwError(error);
        })).subscribe(response => {
        if(response && response.status === 201) {
          this.getTableData();
          this.addTariffTypeForm.reset();
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: response.message,
            showConfirmButton: false,
            timer: 1500
          })
        }
      })
    }
  }
  onUpdateTariffType(): void {
    if (this.editTariffTypeForm.valid) {
      Swal.fire({
        title: 'Are you sure you want to update this record?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'
      }).then((result) => {
        if (result.isConfirmed) {
          const formData: TariffTypeAndCompany = {
            id: this.editTariffTypeForm.value.id,
            name: this.editTariffTypeForm.value.type.trim()
          };
          this._utilityService.editTariffTypes(formData).pipe(
            catchError(error => {
              if (error.status === 400) {
                this.toaster.error(error.error.message);
                this.resetForm();
              } else {
                this.toaster.error('An error occurred. Please try again later.');
                this.resetForm();
              }
              return throwError(error);
            })
          ).subscribe(response => {
            if (response && response.status === 200) {
              this.getTableData();
              this.resetForm();
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: response.message,
                showConfirmButton: false,
                timer: 1500
              });
            }
          });
        }
      });
    }
  }
  

  private updateQueryParams(paramsToUpdate: any): void {
    this.latestQueryParams = {
        ...this.latestQueryParams,
        ...paramsToUpdate
    };
    console.log('Updated queryParams:', this.latestQueryParams);

    if (this.latestQueryParams.search === '') {
        this.latestQueryParams.search = null;
    }
    if (this.latestQueryParams.page === 1) {
        this.latestQueryParams.page = null;
    }
    
    if (this.latestQueryParams.size === 10) {
        this.latestQueryParams.size = null;
    }
    if (this.latestQueryParams.size === 10) {
        this.latestQueryParams.size = null;
    }
    if (this.latestQueryParams.sort === null) {
        delete this.latestQueryParams.sort;
    }
    
    if (this.latestQueryParams.column === null) {
        delete this.latestQueryParams.column;
    }

    if (this.latestQueryParams.page === 1 || (this.latestQueryParams.page && (this.latestQueryParams.search))) {
        if (this.latestQueryParams.page > 1) {
            this.latestQueryParams.page = paramsToUpdate.page;
            if (this.latestQueryParams.size > 10) {
                this.latestQueryParams.size = paramsToUpdate.size;
            }
        } else {
            this.latestQueryParams.page = null;
        }
    } 
    
    if (!this.next_page && (paramsToUpdate.size || paramsToUpdate.search)) {
        // Only include size or search in the query params
        this.latestQueryParams = {
            size: paramsToUpdate.size || null,
            search: paramsToUpdate.search || null
        };
    }
    this.getTableData(); 
}

private getTableData(): void {
    const name = this.latestQueryParams['search'] || this.searchElement;
    const page = this.latestQueryParams['page'] ? +this.latestQueryParams['page'] : 1;
    const size = this.latestQueryParams['size'] ? +this.latestQueryParams['size'] : 10;
    const sort = this.latestQueryParams['sort'] || null;
    const column = this.latestQueryParams['column'] || null;
    this.getAllTariffTypes(name, page, size,sort,column);
}


getAllTariffTypes(name: string, page: number, size: number,sort:string | null ,column :string | null): void {
    this.loader = true;
    this.tariffList = [];
    this.serialNumberArray = [];
    this._utilityService
        .receiveAllTraiffTypes(name, page, size,sort,column)
        .subscribe((res: any) => {
            if (res && res['status'] === 200) {
                this.tariffList = res.data;
                this.tariffList = res.data.map((tariff: any, index: number) => ({
                    ...tariff,
                    srNo: (res.pagination.current_page - 1) * size + index + 1
                }));
                console.log(this.tariffList);
                this.current_page = res.pagination.current_page;
                this.next_page = res.pagination.next_page;
                this.previous_page = res.pagination.previous_page;
                this.total_pages = res.pagination.total_pages;
                this.total_items = res.pagination.total_records;
                this.enablePagination();
            }
            this.loader = false;
        });
}
public searchData(event: Event): void {
    this.searchElement = (event.target as HTMLInputElement)?.value || '';
    console.log('search Value', this.searchElement);
    this.searchSubject.next(this.searchElement);
}

  goToPage(page: number): void {
    console.log('move to page', page);
    if (page >= 1 && page <= this.total_pages) {
        this.updateQueryParams({page:page});
    }
}

navigatePage(direction: string): void {
    if (direction === 'prev' && this.current_page > 1) {
        this.goToPage(this.current_page - 1);
    } else if (direction === 'next' && this.next_page !== null) {
        this.goToPage(this.current_page + 1);
    }
}

// Add a method to navigate to the entered page
navigateToPage(): void {
    if (this.pageToGo >= 1 && this.pageToGo <= this.total_pages) {
        this.goToPage(this.pageToGo);
    }
}

public changePageSize(): void {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.pageSize = this.pageSizeControl.value!;
    this.updateQueryParams({size:this.pageSize})
    }

    enablePagination() {
      const linkElement = document.getElementById('prevPage');

      if (this.previous_page === null) {
          linkElement.setAttribute('disabled', 'true');
          linkElement.style.pointerEvents = 'none';
          linkElement.style.color = 'gray'; 
          linkElement.addEventListener('click', (event) => {
              event.preventDefault(); 
          });
      } else {
          linkElement.removeAttribute('disabled');
          linkElement.style.pointerEvents = 'auto';
          linkElement.style.color = ''; 
          linkElement.removeEventListener('click', (event) => {
              event.preventDefault();
          });
      }
  }

  deleteTariffType(tariff_id:number) {
    Swal.fire({
      title: 'Are you sure you want to delete Tariff Type?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        const formData = {
          id :tariff_id
        }
        this._utilityService.deleteTariffTypes(formData).pipe(
          catchError(error => {
            if (error.status === 400) {
              this.toaster.error(error.error.message);
              this.resetForm();
            } else {
              this.toaster.error('An error occurred. Please try again later.');
              this.resetForm();
            }
            return throwError(error);
          })).subscribe(res => {
          if (res && res.status === 200) {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: res.message,
              showConfirmButton: false,
              timer: 1500
            })
            // Refresh the table data
            this.getTableData();
          } 
          else {
            this.toaster.error(res.message)
            this.resetForm();
          }
        }, error => {
          this.toaster.error(error.error.message)
          this.resetForm();
        });
      }
    });
  }
  
}
