<section class="dashboard-main container-fluid">
    <div class="row mb-4 pt-4 block-topbar">
        <div
            class="col-12 d-sm-flex align-items-center justify-content-between"
        >
            <div class="breadcrumb-main">
                <ul class="breadcrumb">
                    <li><a href="./">Tariff</a></li>
                    <li class="skip-arrow">Tariff Rates</li>
                </ul>
            </div>
        </div>
    </div>
    <div class="contents-main">
        <div class="row block-topbar filter-row">
            <!-- align-items-center justify-content-between -->
            <div class="col-md-auto d-sm-flex">
                <label class="show-entries-list">
                    Show
                    <!-- (change)="changePageSize()" -->
                    <!-- [formControl]="pageSizeControl" -->
                    <select
                        [formControl]="pageSizeControl"
                        class="form-control form-control-sm"
                        (change)="changePageSize()"
                    >
                        <option
                            *ngFor="let limit of pageRecordOptions"
                            [value]="limit.value"
                        >
                            {{ limit.label }}
                        </option>
                    </select>
                    Entries
                </label>
            </div>
            <div class="col-md-2 col-sm-4">
                <div class="form-group role">
                    <ng-select
                        (change)="selectCompanyName($event)"
                        placeholder="Company Name"
                    >
                        <ng-option
                            *ngFor="let Item of companyList"
                            [value]="Item.id"
                            >{{ Item.name }}</ng-option
                        >
                    </ng-select>
                </div>
            </div>
            <div class="col-md-2 col-sm-4">
                <div class="form-group role">
                    <ng-select
                        (change)="selectTariffType($event)"
                        placeholder="Tariff Type"
                    >
                        <ng-option
                            *ngFor="let Item of tariffTypeList"
                            [value]="Item.id"
                            >{{ Item.name }}</ng-option
                        >
                    </ng-select>
                </div>
            </div>
            <div class="col-md-2 col-sm-4">
                <div class="form-group role">
                    <ng-select
                        (change)="selectTariffYear($event)"
                        placeholder="Year"
                    >
                        <ng-option
                            *ngFor="let Item of tariffYear"
                            [value]="Item.value"
                            >{{ Item.label }}</ng-option
                        >
                    </ng-select>
                </div>
            </div>
            <div class="col-md-auto col-btn">
                <button
                    type="button"
                    class="btn btn-orange btn-sm btn-label waves-effect waves-light"
                    (click)="addTariffPopUp(content)"
                >
                    <i
                        class="ri-add-line label-icon align-middle fs-16 me-2"
                    ></i>
                    Add New
                </button>
            </div>
        </div>
        <div class="table-wrap">
            <div class="table-main">
                <table class="table-data">
                    <thead>
                        <tr>
                            <th scope="col">Sr. No</th>
                            <th scope="col">Company Name</th>
                            <th scope="col">Tariff Type</th>
                            <th scope="col">Tariff Year</th>
                            <th
                                scope="col"
                                style="width: 80px; text-align: center"
                            >
                                Actions
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of tariffList; let i = index">
                            <td>{{ item.srNo }}</td>

                            <td>{{ item.tariff_company }}</td>
                            <td>{{ item.tariff_type }}</td>
                            <td>{{ item.year }}</td>

                            <!-- <td style="width: 40px; text-align: center">
                                <div class="d-flex justify-content-center">
                                    <button
                                        type="button"
                                        (click)="
                                            openEditPopUp(item, contentupdate)
                                        "
                                        class="dropdown-item editcategory d-inline"
                                        data-toggle="modal"
                                        data-target="#edit-category-vt"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="14.433"
                                            height="18"
                                            viewBox="8 4 14.433 18"
                                        >
                                            <path
                                                d="M8.9 20.2h12.6a.9.9 0 0 1 0 1.8H8.9a.9.9 0 1 1 0-1.8ZM8 15.7l9-9 2.7 2.7-9 9H8v-2.7Zm9.9-9.9L19.7 4l2.7 2.7-1.8 1.8-2.7-2.7Z"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    </button>
                                    
                                    <button
                                        type="button"
                                        class="dropdown-item deletecategory d-inline"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="15.017"
                                            height="18"
                                            viewBox="9 6 15.017 18"
                                        >
                                            <path
                                                d="M10.5 22.004c0 1.098.9 1.996 2 1.996h8c1.1 0 2-.898 2-1.996V10.5h-12v11.504ZM24 7.5h-3.75L18.994 6h-4.988L12.75 7.5H9V9h15V7.5Z"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    </button>
                                </div>
                            </td> -->
                            <td>
                                <ul class="list-inline hstack gap-2 mb-0">
                                    <li
                                        class="list-inline-item"
                                        data-bs-toggle="tooltip"
                                        data-bs-trigger="hover"
                                        data-bs-placement="top"
                                        title="View"
                                        (click)="
                                            viewTariffPopUp(item, viewContent)
                                        "
                                    >
                                        <a
                                            data-bs-toggle="modal"
                                            title="View Tariff Rates"
                                            class="text-primary d-inline-block"
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="21.841"
                                                height="14.56"
                                                viewBox="0 0 21.841 14.56"
                                            >
                                                <g
                                                    id="eye"
                                                    transform="translate(-1.498 -9)"
                                                >
                                                    <circle
                                                        id="Ellipse_1436"
                                                        data-name="Ellipse 1436"
                                                        cx="3.105"
                                                        cy="3.105"
                                                        r="3.105"
                                                        transform="translate(9.315 13.175)"
                                                        fill="currentColor"
                                                    />
                                                    <path
                                                        id="Path_5515"
                                                        data-name="Path 5515"
                                                        d="M23.1,15.488a15.422,15.422,0,0,0-4.517-4.574A11.43,11.43,0,0,0,12.4,9a10.9,10.9,0,0,0-5.654,1.643,16.574,16.574,0,0,0-4.991,4.826,1.452,1.452,0,0,0-.029,1.617A15.2,15.2,0,0,0,6.2,21.666a11.271,11.271,0,0,0,6.2,1.894,11.478,11.478,0,0,0,6.2-1.9,15.2,15.2,0,0,0,4.508-4.592,1.465,1.465,0,0,0,0-1.582Zm-10.685,5.16a4.368,4.368,0,1,1,4.368-4.368,4.368,4.368,0,0,1-4.368,4.368Z"
                                                        fill="currentColor"
                                                    />
                                                </g>
                                            </svg>
                                        </a>
                                    </li>

                                    <li
                                        class="list-inline-item edit"
                                        data-bs-toggle="tooltip"
                                        data-bs-trigger="hover"
                                        data-bs-placement="top"
                                        title="Edit"
                                    >
                                        <a
                                            title="Add tariff rates"
                                            class="d-inline-block edit-item-btn"
                                            (click)="
                                                openEditPopUp(
                                                    item,
                                                    contentupdate
                                                )
                                            "
                                            data-toggle="modal"
                                            data-target="#edit-category-vt"
                                        >
                                            <!-- <i class="ri-pencil-fill fs-16"></i> -->
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="14.433"
                                                height="18"
                                                viewBox="8 4 14.433 18"
                                            >
                                                <path
                                                    d="M8.9 20.2h12.6a.9.9 0 0 1 0 1.8H8.9a.9.9 0 1 1 0-1.8ZM8 15.7l9-9 2.7 2.7-9 9H8v-2.7Zm9.9-9.9L19.7 4l2.7 2.7-1.8 1.8-2.7-2.7Z"
                                                    fill="currentColor"
                                                />
                                            </svg>
                                        </a>
                                    </li>
                                    <li
                                        class="list-inline-item delete"
                                        data-bs-toggle="tooltip"
                                        data-bs-trigger="hover"
                                        data-bs-placement="top"
                                        title="Delete"
                                    >
                                        <a
                                            title="Delete Tariff Rates"
                                            data-bs-toggle="modal"
                                            class="d-inline-block edit-item-btn"
                                            (click)="deleteTariffRate(item.id)"
                                        >
                                            <!-- <i class=" ri-close-circle-line  "></i>  -->
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="15.017"
                                                height="18"
                                                viewBox="9 6 15.017 18"
                                            >
                                                <path
                                                    d="M10.5 22.004c0 1.098.9 1.996 2 1.996h8c1.1 0 2-.898 2-1.996V10.5h-12v11.504ZM24 7.5h-3.75L18.994 6h-4.988L12.75 7.5H9V9h15V7.5Z"
                                                    fill="currentColor"
                                                />
                                            </svg>
                                        </a>
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr *ngIf="tariffList.length === 0">
                            <td colspan="100%">
                                <p class="not-found-message p-4 text-center">
                                    No records found.
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="table-meta">
                <div class="entries">
                    <span>Total Records: {{ this.total_items }}</span>
                </div>
                <nav class="table-pagination">
                    <ul>
                        <li>
                            <div class="go-to-page d-flex align-items-center">
                                <label>Go to Page:</label>
                                <input
                                    type="number"
                                    class="form-control form-control-sm"
                                    [(ngModel)]="pageToGo"
                                    min="1"
                                    [max]="total_pages"
                                    (keydown.enter)="
                                        goToPage(pageToGo); pageToGo = null
                                    "
                                />
                                <div class="input-group-append">
                                    <button
                                        class="btn btn-primary"
                                        type="button"
                                        (click)="
                                            goToPage(pageToGo); pageToGo = null
                                        "
                                        [disabled]="
                                            pageToGo < 1 ||
                                            pageToGo > total_pages
                                        "
                                    >
                                        Go
                                    </button>
                                </div>
                            </div>
                        </li>
                        <li>
                            <a
                                class="init-bg"
                                id="firstPage"
                                (click)="goToPage(1)"
                                [class.disabled]="current_page === 1"
                                >1</a
                            >
                        </li>
                        <li>
                            <a
                                class="no-bg"
                                id="prevPage"
                                (click)="navigatePage('prev')"
                                ><i class="fa fa-chevron-left"></i
                            ></a>
                        </li>
                        <li>
                            <a class="active">{{ current_page }}</a>
                        </li>
                        <li>
                            <a
                                class="no-bg"
                                id="nextPage"
                                (click)="navigatePage('next')"
                                [class.disabled]="current_page === total_pages"
                                ><i class="fa fa-chevron-right"></i
                            ></a>
                        </li>
                        <li>
                            <a
                                class="init-bg"
                                id="lastPage"
                                (click)="goToPage(total_pages)"
                                [class.disabled]="current_page === total_pages"
                                >{{ this.total_pages }}</a
                            >
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
</section>

<ng-template #content let-c="close" let-d="dismiss">
    <form [formGroup]="addTariffRatesForm" (ngSubmit)="onAddTariffRate()">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Add Tariff Rates</h4>
            <button
                type="button"
                class="btn-close"
                aria-label="Close"
                (click)="d('Cross click'); resetForm()"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13.633"
                    height="14.25"
                    viewBox="1222.583 108.567 13.633 14.25"
                >
                    <g data-name="Group 2829">
                        <g data-name="chevron-back">
                            <path
                                d="m1224 110 5.393 5.393 5.393-5.393"
                                stroke-linejoin="round"
                                stroke-linecap="round"
                                stroke-width="2"
                                stroke="#fff"
                                fill="transparent"
                                data-name="Path 5498"
                            />
                        </g>
                        <g data-name="chevron-back">
                            <path
                                d="m1224 121.393 5.393-5.393 5.392 5.393"
                                stroke-linejoin="round"
                                stroke-linecap="round"
                                stroke-width="2"
                                stroke="#fff"
                                fill="transparent"
                                data-name="Path 5498"
                            />
                        </g>
                    </g>
                </svg>
            </button>
        </div>

        <div class="modal-body">
            <div class="row">
                <div class="col-md-12 form-styles my-3">
                    <label for="typeahead-basic">Company Name</label>
                    <ng-select formControlName="tariff_company">
                        <ng-option
                            *ngFor="let tariff of companyList"
                            [value]="tariff.id"
                            >{{ tariff.name }}</ng-option
                        >
                    </ng-select>
                    <small
                        class="text-danger"
                        *ngIf="
                            !addTariffRatesForm.get('tariff_company').valid &&
                            addTariffRatesForm.get('tariff_company').touched
                        "
                        >Please Select Company Name</small
                    >
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 form-styles my-3">
                    <label for="typeahead-basic">Tariff Type</label>
                    <ng-select formControlName="tariff_type">
                        <ng-option
                            *ngFor="let tariff of tariffTypeList"
                            [value]="tariff.id"
                            >{{ tariff.name }}</ng-option
                        >
                    </ng-select>
                    <small
                        class="text-danger"
                        *ngIf="
                            !addTariffRatesForm.get('tariff_type').valid &&
                            addTariffRatesForm.get('tariff_type').touched
                        "
                        >Please Select Tariff Type</small
                    >
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 my-3">
                    <label for="typeahead-basic"> Year</label>

                    <ng-select formControlName="year">
                        <ng-option
                            *ngFor="let tariff of tariffYear"
                            [value]="tariff.value"
                            >{{ tariff.label }}</ng-option
                        >
                    </ng-select>
                    <small
                        class="text-danger"
                        *ngIf="
                            !addTariffRatesForm.get('year').valid &&
                            addTariffRatesForm.get('year').touched
                        "
                        >Please Select Year</small
                    >
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 my-3">
                    <label for="typeahead-basic">
                        Tariff Rates (Monthwise)</label
                    >
                <div class="rates-month-holder">
                    <div class="row">
                        <div
                            *ngFor="let month of months"
                            class="col-md-3 col-sm-4 my-3"
                        >
                            <label [for]="month">{{ month }}</label>
                            <input
                                type="number"
                                min="1"
                                class="form-control form-control-sm"
                                formControlName="{{ month }}"
                            />
                            <div
                                *ngIf="
                                    addTariffRatesForm.get(month).invalid &&
                                    (addTariffRatesForm.get(month).dirty ||
                                        addTariffRatesForm.get(month).touched)
                                "
                            >
                                <small
                                    class="text-danger"
                                    *ngIf="
                                        addTariffRatesForm.get(month).errors
                                            ?.required
                                    "
                                >
                                    {{ month }} rate is required.
                                </small>
                                <small
                                    class="text-danger"
                                    *ngIf="
                                        addTariffRatesForm.get(month).errors
                                            ?.pattern &&
                                        !addTariffRatesForm.get(month).errors
                                            ?.min
                                    "
                                >
                                    Please enter a valid number with up to 2
                                    decimal places.
                                </small>
                                <small
                                    class="text-danger"
                                    *ngIf="
                                        addTariffRatesForm.get(month).errors
                                            ?.min
                                    "
                                >
                                    Rate must be a greater than 1.
                                </small>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
        <div class="modal-footer no-bg">
            <button
                type="button"
                class="btn btn-outline-dark"
                aria-label="Close"
                (click)="d('Cross click'); resetForm()"
            >
                Cancel
            </button>
            <button
                type="button"
                class="btn btn-orange"
                [disabled]="addTariffRatesForm.invalid"
                (click)="d('Cross click')"
                type="submit"
            >
                Save
            </button>
        </div>
    </form>
</ng-template>

<ng-template #contentupdate let-c="close" let-d="dismiss">
    <!-- (ngSubmit)="cardDetail(payment,content)" #payment="ngForm" -->
    <form [formGroup]="editTariffRatesForm" (ngSubmit)="onUpdateTariffRate()">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">
                Edit Tariff Rates
            </h4>
            <button
                type="button"
                class="btn-close"
                aria-label="Close"
                (click)="d('Cross click'); resetForm()"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13.633"
                    height="14.25"
                    viewBox="1222.583 108.567 13.633 14.25"
                >
                    <g data-name="Group 2829">
                        <g data-name="chevron-back">
                            <path
                                d="m1224 110 5.393 5.393 5.393-5.393"
                                stroke-linejoin="round"
                                stroke-linecap="round"
                                stroke-width="2"
                                stroke="#fff"
                                fill="transparent"
                                data-name="Path 5498"
                            />
                        </g>
                        <g data-name="chevron-back">
                            <path
                                d="m1224 121.393 5.393-5.393 5.392 5.393"
                                stroke-linejoin="round"
                                stroke-linecap="round"
                                stroke-width="2"
                                stroke="#fff"
                                fill="transparent"
                                data-name="Path 5498"
                            />
                        </g>
                    </g>
                </svg>
            </button>
        </div>

        <div class="modal-body">
            <div class="row">
                <div class="col-md-12 form-styles my-3">
                    <label for="typeahead-basic">Company Name</label>
                    <ng-select formControlName="tariff_company">
                        <ng-option
                            *ngFor="let tariff of companyList"
                            [value]="tariff.id"
                            >{{ tariff.name }}</ng-option
                        >
                    </ng-select>
                    <small
                        class="text-danger"
                        *ngIf="
                            !editTariffRatesForm.get('tariff_company').valid &&
                            editTariffRatesForm.get('tariff_company').touched
                        "
                        >Please Select Company Name</small
                    >
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 form-styles my-3">
                    <label for="typeahead-basic">Tariff Type</label>
                    <ng-select formControlName="tariff_type">
                        <ng-option
                            *ngFor="let tariff of tariffTypeList"
                            [value]="tariff.id"
                            >{{ tariff.name }}</ng-option
                        >
                    </ng-select>
                    <small
                        class="text-danger"
                        *ngIf="
                            !editTariffRatesForm.get('tariff_type').valid &&
                            editTariffRatesForm.get('tariff_type').touched
                        "
                        >Please Select Tariff Type</small
                    >
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 my-3">
                    <label for="typeahead-basic"> Year</label>

                    <ng-select formControlName="year">
                        <ng-option
                            *ngFor="let tariff of tariffYear"
                            [value]="tariff.value"
                            >{{ tariff.label }}</ng-option
                        >
                    </ng-select>
                    <small
                        class="text-danger"
                        *ngIf="
                            !editTariffRatesForm.get('year').valid &&
                            editTariffRatesForm.get('year').touched
                        "
                        >Please Select Year</small
                    >
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 my-3">
                    <label for="typeahead-basic">
                        Tariff Rates (Monthwise)</label
                    >
                    <div class="rates-month-holder">
                        <div class="row">
                            <div
                                *ngFor="let month of months"
                                class="col-md-3 col-sm-4 my-3"
                            >
                                <label [for]="month">{{ month }}</label>
                                <input
                                    type="number"
                                    min="1"
                                    class="form-control form-control-sm"
                                    formControlName="{{ month }}"
                                />
                                <div
                                    *ngIf="
                                        editTariffRatesForm.get(month)
                                            .invalid &&
                                        (editTariffRatesForm.get(month).dirty ||
                                            editTariffRatesForm.get(month)
                                                .touched)
                                    "
                                >
                                    <small
                                        class="text-danger"
                                        *ngIf="
                                            editTariffRatesForm.get(month)
                                                .errors?.required
                                        "
                                    >
                                        {{ month }} rate is required.
                                    </small>
                                    <small
                                        class="text-danger"
                                        *ngIf="
                                            editTariffRatesForm.get(month)
                                                .errors?.pattern &&
                                            !editTariffRatesForm.get(month)
                                                .errors?.min
                                        "
                                    >
                                        Please enter a valid number with up to 2
                                        decimal places.
                                    </small>
                                    <small
                                        class="text-danger"
                                        *ngIf="
                                            editTariffRatesForm.get(month)
                                                .errors?.min
                                        "
                                    >
                                    Rate must be a greater than 1.
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer no-bg">
            <button
                type="button"
                class="btn btn-outline-dark"
                aria-label="Close"
                (click)="d('Cross click'); resetForm()"
            >
                Cancel
            </button>
            <button
                type="button"
                class="btn btn-orange"
                [disabled]="editTariffRatesForm.invalid"
                (click)="d('Cross click')"
                type="submit"
            >
                Save
            </button>
        </div>
    </form>
</ng-template>

<!-- View Tariff Rates -->
<ng-template #viewContent let-c="close" let-d="dismiss">
    <!-- (ngSubmit)="cardDetail(payment,content)" #payment="ngForm" -->
    <form [formGroup]="editTariffRatesForm">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">
                View Tariff Rates
            </h4>
            <button
                type="button"
                class="btn-close"
                aria-label="Close"
                (click)="d('Cross click'); resetForm()"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13.633"
                    height="14.25"
                    viewBox="1222.583 108.567 13.633 14.25"
                >
                    <g data-name="Group 2829">
                        <g data-name="chevron-back">
                            <path
                                d="m1224 110 5.393 5.393 5.393-5.393"
                                stroke-linejoin="round"
                                stroke-linecap="round"
                                stroke-width="2"
                                stroke="#fff"
                                fill="transparent"
                                data-name="Path 5498"
                            />
                        </g>
                        <g data-name="chevron-back">
                            <path
                                d="m1224 121.393 5.393-5.393 5.392 5.393"
                                stroke-linejoin="round"
                                stroke-linecap="round"
                                stroke-width="2"
                                stroke="#fff"
                                fill="transparent"
                                data-name="Path 5498"
                            />
                        </g>
                    </g>
                </svg>
            </button>
        </div>

        <div class="modal-body">
            <div class="row">
                <div class="col-md-12 my-3">
                    <label for="typeahead-basic">Company Name</label>
                    <input
                        type="text"
                        class="form-control"
                        formControlName="tariff_company"
                        readonly
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 my-3">
                    <label for="typeahead-basic">Tariff Type</label>
                    <input
                        type="text"
                        class="form-control"
                        formControlName="tariff_type"
                        readonly
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 my-3">
                    <label for="typeahead-basic"> Year</label>
                    <input
                        type="text"
                        class="form-control"
                        formControlName="year"
                        readonly
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 my-3">
                    <label for="typeahead-basic">
                        Tariff Rates (Monthwise)</label
                    >
                <div class="rates-month-holder view">
                    <div class="row">
                        <div
                            *ngFor="let month of months"
                            class="col-md-3 col-sm-4 my-3"
                        >
                            <label [for]="month">{{ month }}</label>
                            <input
                                type="number"
                                min="0"
                                class="form-control form-control-sm"
                                formControlName="{{ month }}"
                                disabled
                            />
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </form>
</ng-template>
