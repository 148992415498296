import { AppState } from '@/store/state';
import { ToggleControlSidebar, ToggleSidebarMenu } from '@/store/ui/actions';
import { UiState } from '@/store/ui/state';
import { Component, HostBinding, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ApiService } from '@services/api.service';
import { AppService } from '@services/app.service';
import { Observable, Subscription } from 'rxjs';
import jwt_decode from 'jwt-decode';
import { login } from '@/Interface/register';
import { NotificationSocketService } from '@services/websocket/notification/notification-socket.service';
import { UtilityService } from '@services/utility.service';
const BASE_CLASSES = '';
// main-header navbar navbar-expand bgParpale
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    @HostBinding('class') classes: string = BASE_CLASSES;
    public ui: Observable<UiState>;
    public searchForm: UntypedFormGroup;
    getauth: string = '';
    getDecodedTokan: string ='';
    authTokan:  login;
    notificationList = []
    unMarkedNotifications:number
    private wsSubscription: Subscription = new Subscription();
    public connectionState: boolean = false;
    public ticket_id:number;
    public user_id:number;
    public getList: any;


    constructor(
        public appService: AppService,
        private store: Store<AppState>,
        private _apiService: ApiService,
        private _utiltiySevice:UtilityService,
        private notificationService: NotificationSocketService
    ) { }

    ngOnInit() {
      
        this.showUserName();
        this.getList = this._utiltiySevice.decodeUserCode();
        this.fetchNotifications();

    }
    markAsRead():void{
        if(!this.notificationList.filter(item=>!item.is_read).length){
            console.log('No unMarked notifictaion Found!')
            return;
        }
        const payload = {
            is_read: true,
        };
    
        // Send the mark-as-read event
        this.notificationService.sendMessage(payload);
        this.notificationList = []

    }

    fetchNotifications():void {
        this.notificationList = []
        this.notificationService.user_id = this.getList.user_id;

        this.wsSubscription.add(this.notificationService
                .getConnectionState()
                .subscribe((state) => {
                    this.connectionState = state;
                })
        );

        this.notificationService.connect();

        this.wsSubscription.add(
            this.notificationService
                .getNotifications()
                .subscribe((notifications) => {
                    if (notifications && notifications.length > 0) {
                        const formattedNotifications = notifications.map((notification) => {
                          return {
                            ...notification,
                            created_at: this.formatDateTime(notification.created_at),
                          };
                        });
                        this.notificationList = [...formattedNotifications, ...this.notificationList];
                        this.unMarkedNotifications = this.notificationList.filter(item=>!item.is_read).length
                      }
                })
        );
    }

    formatDateTime(dateTimeString: string): string {
        return dateTimeString.replace(/(\d{2} \w{3} \d{4}) (\d{2}:\d{2} [AP]M)/, '$1 | $2');
      }

    private disconnectWebSocket() {
        this.notificationService.disconnect();
        this.wsSubscription.unsubscribe(); 
        this.notificationList = []
    }

    ngOnDestroy() {
        this.disconnectWebSocket();
    }

    profilePic()
    {
        return this.appService.profileImage$;
    }
    logout() {
        this.appService.logout();
    }

    onToggleMenuSidebar() {
        this.store.dispatch(new ToggleSidebarMenu());
    }

    onToggleControlSidebar() {
        this.store.dispatch(new ToggleControlSidebar());
    }
    showUserName() {
        this.getDecodedTokan = localStorage.getItem('tokan');

        this.authTokan =   jwt_decode(this.getDecodedTokan);
        console.log("🚀 ~ file: header.component.ts:53 ~ HeaderComponent ~ showUserName ~ this.authTokan", this.authTokan)
 
    
    }
}
