<div class="loaderwrapper" *ngIf="this.loader">
    <span class="loader" ></span> 
</div> 
 <section class="dashboard-main container-fluid">
    <div class="row mb-0 pt-4 block-topbar">
        <div class="col-12 d-sm-flex align-items-center justify-content-between mb-4">
            <h4 class="mb-sm-0">Ticket List</h4>
            
        </div>
            <div class="col-12 d-sm-flex justify-content-between align-items-center">
            <div class="dataTables_length p-0">
                <label class="show-entries-list">
                  Show
                  <!-- (change)="changePageSize()" -->
                  <!-- [formControl]="pageSizeControl" -->
                  <select [formControl]="pageSizeControl" (change)="changePageSize()"
                    class="form-control form-control-sm">
                    <option *ngFor="let limit of pageRecordOptions" [value]="limit.value">{{limit.label}}</option>
                  </select>
                  Entries
                </label>
            </div>
            <div class="d-flex justify-content-end pb-3">
              <div class="row userSearch d-flex justify-content-center pe-3 m-0">
                  <div class="userSearcForm form-styles p-0">
                      <input class="form-control searchTerm" type="search" name="ticketList" placeholder="Search" (input)="searchData($event)"  />
                      <span class="search-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="12.973" height="12.973" viewBox="0 0 12.973 12.973">
                          <g id="search-outline" transform="translate(0.5 0.5)">
                            <path id="Path_2581" data-name="Path 2581" d="M9.313,4.5a4.813,4.813,0,1,0,4.813,4.813A4.813,4.813,0,0,0,9.313,4.5Z" transform="translate(-4.5 -4.5)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="1"/>
                            <path id="Path_2582" data-name="Path 2582" d="M23.786,23.786l3.362,3.362" transform="translate(-15.382 -15.381)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1"/>
                          </g>
                        </svg>
                      </span>
                  </div>
              </div>
            </div>   
        </div>
      </div>
    <section class="contents-main">
    
        <div class="table-wrap">
            <div class="table-main">
                <ngx-datatable #myTable [style.width]="'100%'"  class="material expandable table-responsive" [rows]="ticketList" [headerHeight]="50"
                [footerHeight]="0" [columnMode]="'force'" [scrollbarH]="true" [rowHeight]="'auto'" [offset]="0"
                [externalSorting]="true" [messages]="{emptyMessage: 'No Ticket found.'}" (sort)="onSort($event)">
                <!-- <ngx-datatable-column  [sortable]="true" name="Sr No." prop="srNo" [width]="100">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{ row.srNo }}
                    </ng-template>
                  </ngx-datatable-column> -->
  
                <!-- <ngx-datatable-column  [sortable]="false" name="Ticket ID" prop="ticket_id" [width]="100">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        <a class="cursor-pointer" (click)="sortTickets('ticket_id')">
                            {{row.ticket_id}}
                          </a>
                      {{ row.getTicketID() }}
                    </ng-template>
                </ngx-datatable-column> -->
                <ngx-datatable-column [sortable]="false" name="Ticket ID" prop="ticket_id" [width]="100">
                    <ng-template ngx-datatable-header-template let-column="column">
                      <a class="cursor-pointer" (click)="sortTickets('ticket_id')">
                        Ticket ID
                        <span class="sort-btn"
                              [ngClass]="{'datatable-icon-sort-unset': sortColumn !== 'ticket_id',
                                          'datatable-icon-up': sortColumn === 'ticket_id' && sortOrder === 'asc',
                                          'datatable-icon-down': sortColumn === 'ticket_id' && sortOrder === 'desc'}">
                        </span>
                      </a>
                    </ng-template>
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      <a class="cursor-pointer" (click)="showTicketList(row, ticketDetail)">
                        {{row.ticket_id}}
                      </a>
                    </ng-template>
                  </ngx-datatable-column>
                  


                  <!-- <ngx-datatable-column [sortable]="false" name="Title" prop="title" [width]="180">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.title}}
                        <a class="cursor-pointer" (click)="sortTickets('title'); showTicketList(row, ticketDetail)">
                            {{row.title}}
                        </a>                
                      {{ row.getTitle() }}
                    </ng-template>
                  </ngx-datatable-column> -->
                  <ngx-datatable-column [sortable]="false" name="Title" prop="title" [width]="180">
                    <ng-template ngx-datatable-header-template let-column="column">
                      <a class="cursor-pointer" (click)="sortTickets('title')">
                        Title
                        <span class="sort-btn"
                              [ngClass]="{'datatable-icon-sort-unset': sortColumn !== 'title',
                                          'datatable-icon-up': sortColumn === 'title' && sortOrder === 'asc',
                                          'datatable-icon-down': sortColumn === 'title' && sortOrder === 'desc'}">
                        </span>
                      </a>
                    </ng-template>
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      <a class="cursor-pointer" (click)="showTicketList(row, ticketDetail)">
                        {{row.title}}
                      </a>
                    </ng-template>
                  </ngx-datatable-column>
                  


                  <!-- <ngx-datatable-column [sortable]="false" name="Plant Name" prop="plant_name">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.plant_name}}
                        <a class="cursor-pointer" (click)="sortTickets('plant_name'); showTicketList(row, ticketDetail) ">
                            {{row.plant_name}}
                        </a>  
                      {{ row.getPlantName() }}
                    </ng-template>
                  </ngx-datatable-column> -->
                  <ngx-datatable-column [sortable]="false" name="Plant Name" prop="plant_name">
                    <ng-template ngx-datatable-header-template let-column="column">
                      <a class="cursor-pointer" (click)="sortTickets('plant_name')">
                        Plant Name
                        <span class="sort-btn"
                              [ngClass]="{'datatable-icon-sort-unset': sortColumn !== 'plant_name',
                                          'datatable-icon-up': sortColumn === 'plant_name' && sortOrder === 'asc',
                                          'datatable-icon-down': sortColumn === 'plant_name' && sortOrder === 'desc'}">
                        </span>
                      </a>
                    </ng-template>
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      <a class="cursor-pointer" (click)="showTicketList(row, ticketDetail)">
                        {{row.plant_name}}
                      </a>
                    </ng-template>
                  </ngx-datatable-column>
                  

                  <!-- <ngx-datatable-column [sortable]="false" name="Source" prop="source">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.source}}
                    </ng-template>
                  </ngx-datatable-column> -->
                  <ngx-datatable-column [sortable]="false" name="Source" prop="source">
                    <ng-template ngx-datatable-header-template let-column="column">
                      <a class="cursor-pointer" (click)="sortTickets('source')">
                        Source
                        <span class="sort-btn"
                              [ngClass]="{'datatable-icon-sort-unset': sortColumn !== 'source',
                                          'datatable-icon-up': sortColumn === 'source' && sortOrder === 'asc',
                                          'datatable-icon-down': sortColumn === 'source' && sortOrder === 'desc'}">
                        </span>
                      </a>
                    </ng-template>
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.source}}
                    </ng-template>
                  </ngx-datatable-column>
                  

                  <ngx-datatable-column name="Status" prop="status" [width]="150" [sortable]="false">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      <div style="width: 180px;" class="priorityList" (click)="toggleDropdownClicked()">
                        <ng-select [(ngModel)]="row.status" [disabled]="row.status === 5" (change)="changeStatus(row, $event)">
                          <ng-option *ngFor="let option of statusDDL" [value]="option.id">{{ option.text }}</ng-option>
                        </ng-select>
                      </div>
                    </ng-template>
                  </ngx-datatable-column>

                  <!-- <ngx-datatable-column  [sortable]="true" name="Created at" prop="created_at" [width]="180">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.created_at | date: 'medium' }}
                      {{ row.getCreatedAt() | date: 'medium' }}
                    </ng-template>
                  </ngx-datatable-column> -->
                  <ngx-datatable-column [sortable]="false" name="Created at" prop="created_at" [width]="180">
                    <ng-template ngx-datatable-header-template let-column="column">
                      <a class="cursor-pointer" (click)="sortTickets('created_at')">
                        Created at
                        <span class="sort-btn"
                              [ngClass]="{'datatable-icon-sort-unset': sortColumn !== 'created_at',
                                          'datatable-icon-up': sortColumn === 'created_at' && sortOrder === 'asc',
                                          'datatable-icon-down': sortColumn === 'created_at' && sortOrder === 'desc'}">
                        </span>
                      </a>
                    </ng-template>
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      {{row.created_at | date: 'medium'}}
                    </ng-template>
                  </ngx-datatable-column>
                  

                  <ngx-datatable-column [sortable]="false" name="Priority" prop="priority" [width]="150" *ngIf="isAdmin">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      <div style="width: 150px;" class="priorityList" *ngIf="isAdmin" (click)="toggleDropdownClicked()">
                        <ng-select [(ngModel)]="row.priority" [disabled]="row.status === 5" (change)="changePriority(row, $event)">
                          <ng-option *ngFor="let option of priority" [value]="option.id">{{ option.name }}</ng-option>
                        </ng-select>
                      </div>
                    </ng-template>
                  </ngx-datatable-column>
                
                  <!-- Assign To (Admin) Column -->
                  <!-- <ngx-datatable-column [sortable]="false" name="Assign To" prop="assigned_to" [width]="180" *ngIf="isAdmin">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      <div style="width: 180px;" class="priorityList" *ngIf="isAdmin" (click)="toggleDropdownClicked()">
                        <ng-select [(ngModel)]="row.assigned_to" [disabled]="row.status === 5" (change)="changeAssignTo(row, $event)">
                          <ng-option *ngFor="let option of user" [value]="option.user_id">{{ option.username }}</ng-option>
                        </ng-select>
                      </div>
                    </ng-template>
                  </ngx-datatable-column> -->
                  <ngx-datatable-column [sortable]="false" name="Assign To" prop="assigned_to" [width]="180" *ngIf="isAdmin">
                    <ng-template ngx-datatable-header-template let-column="column">
                      <a class="cursor-pointer" (click)="sortTickets('assigned_to')">
                        Assign To
                        <span class="sort-btn"
                              [ngClass]="{'datatable-icon-sort-unset': sortColumn !== 'assigned_to',
                                          'datatable-icon-up': sortColumn === 'assigned_to' && sortOrder === 'asc',
                                          'datatable-icon-down': sortColumn === 'assigned_to' && sortOrder === 'desc'}">
                        </span>
                      </a>
                    </ng-template>
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      <div style="width: 180px;" class="priorityList" *ngIf="isAdmin" (click)="toggleDropdownClicked()">
                        <ng-select [(ngModel)]="row.assigned_to" [disabled]="row.status === 5" (change)="changeAssignTo(row, $event)">
                          <ng-option *ngFor="let option of user" [value]="option.user_id">{{ option.username }}</ng-option>
                        </ng-select>
                      </div>
                    </ng-template>
                  </ngx-datatable-column>                  
                
                  <!-- Re-Open (Admin) Column -->
                  <!-- Re-Open Column -->
<ngx-datatable-column [sortable]="false" name="Re-Open" [width]="80" *ngIf="isAdmin">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <div class="priorityList text-center">
        <ng-container *ngIf="row.status === 5; else notReopen">
          <a (click)="statuToReopen(row)">
            <!-- Re-Open Icon -->
            <svg xmlns="http://www.w3.org/2000/svg" width="30.033" height="24.017" viewBox="1755.983 219.983 30.033 24.017">
              <g data-name="Group 2797">
                <g data-name="folder-open-sharp">
                  <path d="m1756.005 227.837 1.786 14.617a1.75 1.75 0 0 0 1.742 1.546h22.937a1.75 1.75 0 0 0 1.741-1.546l1.784-14.617a.75.75 0 0 0-.745-.837h-28.5a.75.75 0 0 0-.745.837Z" fill="#ED5E5E" fill-rule="evenodd" data-name="Path 5511"/>
                  <path d="M1784 223.75a1.75 1.75 0 0 0-1.75-1.75h-11.947l-3-2h-7.553a1.75 1.75 0 0 0-1.75 1.75V225h26v-1.25Z" fill="#ED5E5E" fill-rule="evenodd" data-name="Path 5512"/>
                </g>
                <g data-name="Group 2794">
                  <path d="M1766 234.436a3.528 3.528 0 0 0 6.75.87m.218 1.265v-1.264h-1.378" stroke-linejoin="round" stroke-linecap="round" stroke-width=".8" stroke="#fff" fill="transparent" data-name="Path 448"/>
                  <path d="M1774.968 235.478a3.528 3.528 0 0 0-6.75-.871m-.219-1.265v1.265h1.379" stroke-linejoin="round" stroke-linecap="round" stroke-width=".8" stroke="#fff" fill="transparent" data-name="Path 5513"/>
                </g>
              </g>
            </svg>
          </a>
        </ng-container>
        <ng-template #notReopen>
          <!-- Display some other content when not reopen -->
          <!-- For example, a different icon or text -->
          <svg xmlns="http://www.w3.org/2000/svg" width="30.033" height="24.017" viewBox="1755.983 219.983 30.033 24.017">
            <g data-name="Group 2797">
              <g data-name="folder-open-sharp">
                <path d="m1756.005 227.837 1.786 14.617a1.75 1.75 0 0 0 1.742 1.546h22.937a1.75 1.75 0 0 0 1.741-1.546l1.784-14.617a.75.75 0 0 0-.745-.837h-28.5a.75.75 0 0 0-.745.837Z" fill="#808080" fill-rule="evenodd" data-name="Path 5511"/>
                <path d="M1784 223.75a1.75 1.75 0 0 0-1.75-1.75h-11.947l-3-2h-7.553a1.75 1.75 0 0 0-1.75 1.75V225h26v-1.25Z" fill="#808080" fill-rule="evenodd" data-name="Path 5512"/>
              </g>
              <g data-name="Group 2794">
                <path d="M1766 234.436a3.528 3.528 0 0 0 6.75.87m.218 1.265v-1.264h-1.378" stroke-linejoin="round" stroke-linecap="round" stroke-width=".8" stroke="#fff" fill="transparent" data-name="Path 448"/>
                <path d="M1774.968 235.478a3.528 3.528 0 0 0-6.75-.871m-.219-1.265v1.265h1.379" stroke-linejoin="round" stroke-linecap="round" stroke-width=".8" stroke="#fff" fill="transparent" data-name="Path 5513"/>
              </g>
            </g>
          </svg>
        </ng-template>
      </div>
    </ng-template>
  </ngx-datatable-column>
  

</ngx-datatable>

                <div class="table-meta">
                    <div class="entries">
                        <span>Total Records: {{this.total_complaints }}</span>               
                    </div>
                    <!-- <nav class="table-pagination">
                        <ul>
                            <li><a class="no-bg" id="prevPage" (click)="this.Pagination(this.previous_page)"><i class="fa fa-chevron-left"></i></a></li>
                            <li><a class="active" [routerLink]="" (click)="Pagination(this.current_page)">{{this.current_page }}</a></li>
                            <li><a (click)="this.Pagination((this.current_page + 1))" *ngIf="(this.current_page + 1) <= this.total_pages">{{(this.current_page) + 1 }}</a></li>
                            <li><a (click)="this.Pagination((this.current_page + 2))"  *ngIf="(this.current_page + 2) <= this.total_pages">{{(this.current_page) + 2}}</a></li>
                            <li class="no-bg" *ngIf="((+this.current_page + 3)) < this.total_pages">...</li>
                            <li><a (click)="this.Pagination(this.total_pages)" *ngIf="(this.current_page + 2) < this.total_pages">{{this.total_pages }}</a></li>
                            <li><a  class="no-bg" id="nextPage"  (click)="this.Pagination(this.next_page)"><i class="fa fa-chevron-right"></i></a></li>
                        </ul>
                    </nav> -->
                    <!-- <nav class="table-pagination">
                        <ul>
                            <li><a class="init-bg" id="firstPage" (click)="goToPage(1)" [class.disabled]="current_page === 1">1</a></li>
                            <li><a class="no-bg" id="prevPage" (click)="navigatePage('prev')"><i class="fa fa-chevron-left"></i></a></li>
                            <li><a class="active">{{ current_page }}</a></li>
                            <li><a class="no-bg" id="nextPage" (click)="navigatePage('next')" [class.disabled]="current_page === total_pages"><i class="fa fa-chevron-right"></i></a></li>
                            <li><a class="init-bg" id="lastPage" (click)="goToPage(total_pages)" [class.disabled]="current_page === total_pages">{{this.total_pages}}</a></li>
                        </ul>
                    </nav>         -->
                    <nav class="table-pagination">
                        <ul>
                            <li>
                                <div class="go-to-page d-flex align-items-center">
                                    <label>Go to Page:</label>
                                    <input type="number" class="form-control form-control-sm" [(ngModel)]="pageToGo" min="1" [max]="total_pages" (keydown.enter)="goToPage(pageToGo); pageToGo = null">
                                    <div class="input-group-append">
                                        <button class="btn btn-primary" type="button" (click)="goToPage(pageToGo); pageToGo = null" [disabled]="pageToGo < 1 || pageToGo > total_pages">Go</button>
                                    </div>
                                </div>
                            </li>                                                                                                
                            <li><a class="init-bg" id="firstPage" (click)="goToPage(1)" [class.disabled]="current_page === 1">1</a></li>
                            <li><a class="no-bg" id="prevPage" (click)="navigatePage('prev')"><i class="fa fa-chevron-left"></i></a></li>
                            <li><a class="active">{{ current_page }}</a></li>
                            <li><a class="no-bg" id="nextPage" (click)="navigatePage('next')" [class.disabled]="current_page === total_pages"><i class="fa fa-chevron-right"></i></a></li>
                            <li><a class="init-bg" id="lastPage" (click)="goToPage(total_pages)" [class.disabled]="current_page === total_pages">{{this.total_pages}}</a></li>
                        </ul>
                    </nav>
                    
                </div>
               
            </div>
        </div>
    </section>
</section>

<ng-template #ticketDetail let-c="close" let-d="dismiss" let-width="400">
    <form>
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Complaint</h4>
            <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click'); onTicketcloseModal()">
                <svg xmlns="http://www.w3.org/2000/svg" width="13.633" height="14.25" viewBox="1222.583 108.567 13.633 14.25">
                    <g data-name="Group 2829"><g data-name="chevron-back"><path d="m1224 110 5.393 5.393 5.393-5.393" stroke-linejoin="round" stroke-linecap="round" stroke-width="2" stroke="#fff" fill="transparent" data-name="Path 5498"/></g><g data-name="chevron-back"><path d="m1224 121.393 5.393-5.393 5.392 5.393" stroke-linejoin="round" stroke-linecap="round" stroke-width="2" stroke="#fff" fill="transparent" data-name="Path 5498"/></g></g>
                </svg>
            </button>
        </div>

        <div class="modal-body">
          <div class="row">
            <div class="col-md-4">
              <!-- First Table (2 Columns: Ticket ID and Title) -->
              <table class="table">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col">Ticket ID</th>
                    <th scope="col">Title</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ ticketListbyid?.ticket_id }}</td>
                    <td>{{ ticketListbyid?.title }}</td>
                  </tr>
                </tbody>
              </table>
            
              <!-- Second Table (2 Columns: Plant Name and Assign TO) -->
              <table class="table">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col">Plant Name</th>
                    <th scope="col">Assign TO</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ ticketListbyid?.plant_name }}</td>
                    <td>{{ ticketListbyid?.assigned_to }}</td>
                  </tr>
                </tbody>
              </table>
            
              <!-- Third Table (2 Columns: Source and Created at) -->
              <table class="table">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col">Source</th>
                    <th scope="col">Created at</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ ticketListbyid?.source }}</td>
                    <td>{{ ticketListbyid?.created_at }}</td>
                  </tr>
                </tbody>
              </table>
            
              <!-- Fourth Table (2 Columns: Priority and Status) -->
              <table class="table">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col">Priority</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ ticketListbyid?.priority }}</td>
                    <td>{{ statusType }}</td>
                  </tr>
                </tbody>
              </table>
            
              <!-- Fifth Table (2 Columns: Category and Sub Category) -->
              <table class="table">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col">Category</th>
                    <th scope="col">Sub Category</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ ticketListbyid?.category }}</td>
                    <td>{{ ticketListbyid?.sub_category }}</td>
                  </tr>
                </tbody>
              </table>
            
              <!-- Sixth Table (2 Columns: Contact and Filed By) -->
              <table class="table">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col">Contact</th>
                    <th scope="col">Filed by</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ ticketListbyid?.contact }}</td>
                    <td>{{ ticketListbyid?.filed_by }}</td>
                  </tr>
                </tbody>
              </table>
            
              <!-- Seventh Table (1 Column: Description) -->
              <table class="table">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col">Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ ticketListbyid?.description }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            
            <div class="col-md-8">
              <div class="chat-block">
                <strong class="title">Comments</strong>
                <!-- [scrollTop]="scrollContainer.nativeElement?.scrollHeight" -->
                <div class="messages-holder"  #scrollContainer>
                    <div *ngFor="let item of ticketComments.slice().reverse();let i = index" >
                    <div class="message-box"
                    *ngIf="matchCommentUser(item.user_id) ===false"
                    
                    [attr.id]="'comment' + i">
                        <div class="avatar">
                            <img src="assets/img/default-profile.png" alt="Image Description">
                        </div>
                        <div class="message-item"  >
                            <strong class="name">{{item.username}}</strong>
                            <div class="message-time">{{ item.created_at | date: 'yyyy-MM-dd HH:mm'}}</div>
                            <div class="message-wrap">
                                <div class="text-message">
                                    <div class="text-options-wrap">

                                        <div class="text" *ngIf="isImageUrl(item.message) === true">
                                            <img class="chat-img" [src]="item.message" alt="Image">
                                            </div>
                                          
                                            
                                            <div class="text" *ngIf="isMp3Url(item.message) === false && isImageUrl(item.message) === false && isVideoFilePath(item.message) === false">
                                            <div *ngIf="isImageUrl(item.message) === false && isVideoFilePath(item.message) === false">
                                                {{item.message}}
                                            </div>
                                            
                                            
                                        </div>
                                        <div class="chat-img" *ngIf="isVideoFilePath(item.message) === true" >
                                            <div class="video-option">

                                                <video controls width="640" height="360">
                                                    <source [src]="item.message" type="video/mp4">
                                                  </video>
                                            </div>
                                            
                                        </div>

                                        <div class="chat-img" *ngIf="isMp3Url(item.message) === true" >
                                           
                                                <audio controls>
                                                    <ng-container *ngFor="let format of audioFormats">
                                                        <source [src]="item.message" [type]="format">
                                                      </ng-container>
                                                  </audio>
                                            
                                            
                                        </div>

                                        
                                        
                                        
                                        <div class="option-dots">
                                            <button class="dots"><span></span></button>
                                        </div>
                                    </div>
                                    <div class="message-time"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="message-box reverse"
                    *ngIf="matchCommentUser(item.user_id) === true" [attr.id]="'comment' + i">
                        <div class="avatar">
                            <img src="assets/img/default-profile.png" alt="Image Description">
                        </div>
                        <div class="message-item" >
                            <strong class="name">{{item.username}}</strong>
                            <div class="message-time">{{ item.created_at | date: 'yyyy-MM-dd HH:mm' }}</div>

                            <div class="message-wrap">
                                <div class="text-message">
                                    <div class="text-options-wrap">

                                        <div class="text" *ngIf="isImageUrl(item.message) === true">
                                            <img class="chat-img" [src]="item.message" alt="Image">
                                            </div>
                                          
                                            
                                            <div class="text" *ngIf="isMp3Url(item.message) === false && isImageUrl(item.message) === false && isVideoFilePath(item.message) === false">
                                            <div *ngIf="isImageUrl(item.message) === false && isVideoFilePath(item.message) === false">
                                                {{item.message}}
                                            </div>
                                            
                                            
                                        </div>
                                        <div class="chat-img" *ngIf="isVideoFilePath(item.message) === true" >
                                            <div class="video-option">
                                                <video controls width="640" height="360">
                                                    <source [src]="item.message" type="video/mp4">
                                                  </video>
                                            </div>
                                            
                                        </div>

                                        <div class="chat-img" *ngIf="isMp3Url(item.message) === true" >
                                           
                                                <audio controls>
                                                    <ng-container *ngFor="let format of audioFormats">
                                                        <source [src]="item.message" [type]="format">
                                                      </ng-container>
                                                  </audio>
                                            
                                            
                                        </div>

                                        
                                        <div class="option-dots">
                                            <button class="dots"><span></span></button>
                                        </div>
                                    </div>
                                    <div class="message-time"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                  </div>
                  <div *ngIf="selectedFile" class="selected-file-info">
                    <p>{{ selectedFile[0].name }}</p>
                </div>
                <div class="messages-form">
                    <div class="fileUpload-box">
                        <button class="add-button">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M15 7H9V1C9 0.447 8.552 0 8 0C7.448 0 7 0.447 7 1V7H1C0.448 7 0 7.447 0 8C0 8.553 0.448 9 1 9H7V15C7 15.553 7.448 16 8 16C8.552 16 9 15.553 9 15V9H15C15.552 9 16 8.553 16 8C16 7.447 15.552 7 15 7" fill="currentColor"/>
                            </svg>
                        </button>
                        <ul class="filelist-option">

                            <li>
                                <label for="fileInput" class="option-button">
                                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5 7C5.828 7 6.5 6.328 6.5 5.5C6.5 4.672 5.828 4 5 4C4.172 4 3.5 4.672 3.5 5.5C3.5 6.328 4.172 7 5 7M15 16H3.561L10.566 10.155C10.812 9.946 11.258 9.947 11.499 10.154L16 13.994V15C16 15.552 15.552 16 15 16M3 2H15C15.552 2 16 2.448 16 3V11.364L12.797 8.632C11.807 7.79 10.258 7.79 9.277 8.626L2 14.698V3C2 2.448 2.448 2 3 2M15 0H3C1.346 0 0 1.346 0 3V15C0 16.654 1.346 18 3 18H15C16.654 18 18 16.654 18 15V3C18 1.346 16.654 0 15 0" fill="currentColor"/>
                                  </svg>
                                </label>
                                <input id="fileInput" type="file" style="display: none;" (change)="handleFileInput($event)">
                              </li>
                           
                          </ul>
                        </div>
                    <div class="message-input">
                        <textarea cols="10" rows="5" id="newMessage" name="newMessage" [(ngModel)]="newMessage" placeholder="Type a message here"></textarea>
                    </div>
                    <div class="message-form-options">
                        <!-- <div class="emojies-box">
                            <button class="emoji-button"> 
                                 <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="12" cy="12" r="10.75" stroke="currentColor" stroke-opacity="1" stroke-width="2.5"/>
                                    <path d="M7.63635 13.6364C8.45647 15.257 10.1028 16.3636 12 16.3636C13.8972 16.3636 15.5435 15.257 16.3636 13.6364" stroke="currentColor" stroke-opacity="1" stroke-width="2.5" stroke-linecap="round"/>
                                    <circle cx="8.72726" cy="8.72726" r="1.09091" fill="currentColor" fill-opacity="1"/>
                                    <circle cx="15.2727" cy="8.72726" r="1.09091" fill="currentColor" fill-opacity="1"/>
                                </svg>
                            </button> 
                        </div> -->
                        
                           <div class="send-box">
                            <button class="send-button" (click)="sendMessage();">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.3892 15.3333C10.3717 15.3333 10.355 15.3325 10.3384 15.3316C9.9617 15.3091 9.64754 15.0358 9.57254 14.6658L8.29087 8.35997C8.2242 8.03163 7.96837 7.7758 7.64004 7.70913L1.3342 6.42663C0.964204 6.35247 0.69087 6.0383 0.66837 5.66163C0.64587 5.28413 0.87837 4.93913 1.2367 4.8208L14.57 0.376633C14.8692 0.274966 15.1992 0.3533 15.4225 0.577466C15.6459 0.8008 15.7234 1.1308 15.6242 1.42997L11.1792 14.7633C11.0659 15.1058 10.7459 15.3333 10.3892 15.3333" fill="currentColor"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            </div>
          </div>

        </div>
    </form>
</ng-template>