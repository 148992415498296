<section class="dashboard-main container-fluid">
    <div class="row mb-4 pt-4 block-topbar">
        <div
            class="col-12 d-sm-flex align-items-center justify-content-between"
        >
            <div class="breadcrumb-main">
                <ul class="breadcrumb">
                    <li><a href="./">Tariff</a></li>
                    <li class="skip-arrow">Tariff Type</li>
                </ul>
            </div>
        </div>
    </div>
    <div class="contents-main">
        <div class="row mb-4 block-topbar">
            <div class="col-12 d-sm-flex align-items-center justify-content-between">
                <label class="show-entries-list">
                    Show
                    <!-- (change)="changePageSize()" -->
                    <!-- [formControl]="pageSizeControl" -->
                    <select
                        [formControl]="pageSizeControl"
                        class="form-control form-control-sm"
                        (change)="changePageSize()"
                    >
                        <option
                            *ngFor="let limit of pageRecordOptions"
                            [value]="limit.value"
                        >
                            {{ limit.label }}
                        </option>
                    </select>
                    Entries
                </label>
                <div class="d-flex justify-content-end">
                    <div class="row userSearch d-flex justify-content-center pe-3 m-0">
                        <div class="userSearcForm form-styles p-0">
                            <input class="form-control searchTerm" type="search" name="userName" (input)="searchData($event)" placeholder="Search"  />
                            <span class="search-icon">
                              <svg xmlns="http://www.w3.org/2000/svg" width="12.973" height="12.973" viewBox="0 0 12.973 12.973">
                                <g id="search-outline" transform="translate(0.5 0.5)">
                                  <path id="Path_2581" data-name="Path 2581" d="M9.313,4.5a4.813,4.813,0,1,0,4.813,4.813A4.813,4.813,0,0,0,9.313,4.5Z" transform="translate(-4.5 -4.5)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="1"/>
                                  <path id="Path_2582" data-name="Path 2582" d="M23.786,23.786l3.362,3.362" transform="translate(-15.382 -15.381)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1"/>
                                </g>
                              </svg>
                            </span>
                        </div>
                        <!-- <div class="col-md-3">
                            <input class="form-control searchTerm" type="search" name="userEmail" [(ngModel)]="userEmail"
                            (ngModelChange)="searchByEmail()" placeholder="Search By Email " />
                        </div> -->
                    </div>
                    <button
                    type="button"
                    class="btn btn-orange btn-sm btn-label waves-effect waves-light" (click)="addTariffPopUp(content)">
                    <i class="ri-add-line label-icon align-middle fs-16 me-2"></i>
                    Add New
                        </button>
                  </div>
            </div>
        </div>
        <div class="table-wrap">
            <div class="table-main">
                <div>
                    <table class="table-data">
                        <thead>
                            <tr>
                                <th scope="col">Sr. No</th>
                                <th scope="col">Tariff Type</th>
    
                                <th
                                    scope="col"
                                    style="width: 80px; text-align: center"
                                >
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of tariffList; let i = index">
                                <td>{{ item.srNo }}</td>
    
                                <td>{{ item.name }}</td>
    
                                <td>
                                    <ul class="list-inline hstack gap-2 mb-0">
                                      <li
                                        class="list-inline-item edit"
                                        data-bs-toggle="tooltip"
                                        data-bs-trigger="hover"
                                        data-bs-placement="top"
                                        title="Edit"
                                      >
                                        <a
                                        title="Edit Teriff Type" 
                                            (click)="
                                                openEditPopUp(item, contentupdate)
                                            "
                                            class=" d-inline-block edit-item-btn"
                                            data-toggle="modal"
                                            data-target="#edit-category-vt"
                                        >
                                          <!-- <i class="ri-pencil-fill fs-16"></i> -->
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14.433" height="18" viewBox="8 4 14.433 18">
                                              <path d="M8.9 20.2h12.6a.9.9 0 0 1 0 1.8H8.9a.9.9 0 1 1 0-1.8ZM8 15.7l9-9 2.7 2.7-9 9H8v-2.7Zm9.9-9.9L19.7 4l2.7 2.7-1.8 1.8-2.7-2.7Z" fill="currentColor"/>
                                          </svg>
                                        </a>
                                      </li>
                                      <li
                                        class="list-inline-item delete"
                                        data-bs-toggle="tooltip"
                                        data-bs-trigger="hover"
                                        data-bs-placement="top"
                                        title="Delete"
                                      >
                                        <a
                                        title="Delete Tariff Type"
                                          data-bs-toggle="modal"
                                          class=" d-inline-block edit-item-btn"
                                          type="button"
                                            (click)="deleteTariffType(item.id)"
                                        >
                                          <!-- <i class=" ri-close-circle-line  "></i>  -->
                                          <svg xmlns="http://www.w3.org/2000/svg" width="15.017" height="18" viewBox="9 6 15.017 18">
                                              <path d="M10.5 22.004c0 1.098.9 1.996 2 1.996h8c1.1 0 2-.898 2-1.996V10.5h-12v11.504ZM24 7.5h-3.75L18.994 6h-4.988L12.75 7.5H9V9h15V7.5Z" fill="currentColor"/>
                                          </svg>
                                        </a>
                                      </li>
                                    </ul>
                                  </td>
                            </tr>
                            <tr *ngIf="tariffList.length === 0">
                                <td colspan="100%">
                                    <p class="not-found-message p-4 text-center">No records found.</p>

                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="table-meta">
                    <div class="entries">
    
                        <span>Total Records: {{this.total_items }}</span>               
                    </div>
                    <nav class="table-pagination">
                      <ul>
                          <li>
                              <div class="go-to-page d-flex align-items-center">
                                  <label>Go to Page:</label>
                                  <input type="number" class="form-control form-control-sm" [(ngModel)]="pageToGo" min="1" [max]="total_pages" (keydown.enter)="goToPage(pageToGo); pageToGo = null">
                                  <div class="input-group-append">
                                      <button class="btn btn-primary" type="button" (click)="goToPage(pageToGo); pageToGo = null" [disabled]="pageToGo < 1 || pageToGo > total_pages">Go</button>
                                  </div>
                              </div>
                          </li>                                                                                                
                          <li><a class="init-bg" id="firstPage" (click)="goToPage(1)" [class.disabled]="current_page === 1">1</a></li>
                          <li><a class="no-bg" id="prevPage" (click)="navigatePage('prev')"><i class="fa fa-chevron-left"></i></a></li>
                          <li><a class="active">{{ current_page }}</a></li>
                          <li><a class="no-bg" id="nextPage" (click)="navigatePage('next')" [class.disabled]="current_page === total_pages"><i class="fa fa-chevron-right"></i></a></li>
                          <li><a class="init-bg" id="lastPage" (click)="goToPage(total_pages)" [class.disabled]="current_page === total_pages">{{this.total_pages}}</a></li>
                      </ul>
                  </nav>
                    
                </div>
            </div>
        </div>
    </div>
</section>

<ng-template #content let-c="close" let-d="dismiss">
    <form [formGroup]="addTariffTypeForm" (ngSubmit)="onAddTariffType()">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Add Tariff Type</h4>
            <button
                type="button"
                class="btn-close"
                aria-label="Close"
                (click)="d('Cross click'); resetForm()"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13.633"
                    height="14.25"
                    viewBox="1222.583 108.567 13.633 14.25"
                >
                    <g data-name="Group 2829">
                        <g data-name="chevron-back">
                            <path
                                d="m1224 110 5.393 5.393 5.393-5.393"
                                stroke-linejoin="round"
                                stroke-linecap="round"
                                stroke-width="2"
                                stroke="#fff"
                                fill="transparent"
                                data-name="Path 5498"
                            />
                        </g>
                        <g data-name="chevron-back">
                            <path
                                d="m1224 121.393 5.393-5.393 5.392 5.393"
                                stroke-linejoin="round"
                                stroke-linecap="round"
                                stroke-width="2"
                                stroke="#fff"
                                fill="transparent"
                                data-name="Path 5498"
                            />
                        </g>
                    </g>
                </svg>
            </button>
        </div>

        <div class="modal-body">
            <div class="row">
                <div class="col-md-12 mb-3 form-styles">
                    <label for="typeahead-basic">Tariff Type</label>
                    <input
                        id="typeahead-basic"
                        type="text"
                        class="form-control"
                        formControlName="type"
                        maxlength="50"
                    />

                    <small
                        class="text-danger"
                        *ngIf="
                            addTariffTypeForm.get('type').hasError('required') &&
                            addTariffTypeForm.get('type').touched
                        "
                        >Please Enter Tariff Type</small>
                        <small  class="text-danger" *ngIf="!addTariffTypeForm.get('type').hasError('required') &&
                                addTariffTypeForm.get('type').hasError('leadingWhitespace') &&
                                addTariffTypeForm.get('type').touched">
                            Tariff Type cannot be a empty.
                        </small>
                        <small class="text-danger" *ngIf="addTariffTypeForm.get('type').errors?.invalidInput">
                            Type cannot be whitespace or only numbers.
                        </small>
                          <small class="text-danger" *ngIf="addTariffTypeForm.get('type').errors?.pattern">
                            Type can only contain alphabetic characters.
                          </small>
                </div>
            </div>
        </div>
        <div class="modal-footer no-bg">
            <button
                type="button"
                class="btn btn-outline-dark"
                aria-label="Close"
                (click)="d('Cross click'); resetForm()"
            >
                Cancel
            </button>
            <button
                type="button"
                class="btn btn-orange"
                [disabled]="addTariffTypeForm.invalid"
                (click)="d('Cross click')"
                type="submit"
            >
                Save
            </button>
        </div>
    </form>
</ng-template>

<ng-template #contentupdate let-c="close" let-d="dismiss">
    <!-- (ngSubmit)="cardDetail(payment,content)" #payment="ngForm" -->
    <form [formGroup]="editTariffTypeForm" (ngSubmit)="onUpdateTariffType()">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">
                Update Tariff Type
            </h4>
            <button
                type="button"
                class="btn-close"
                aria-label="Close"
                (click)="d('Cross click'); resetForm()"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13.633"
                    height="14.25"
                    viewBox="1222.583 108.567 13.633 14.25"
                >
                    <g data-name="Group 2829">
                        <g data-name="chevron-back">
                            <path
                                d="m1224 110 5.393 5.393 5.393-5.393"
                                stroke-linejoin="round"
                                stroke-linecap="round"
                                stroke-width="2"
                                stroke="#fff"
                                fill="transparent"
                                data-name="Path 5498"
                            />
                        </g>
                        <g data-name="chevron-back">
                            <path
                                d="m1224 121.393 5.393-5.393 5.392 5.393"
                                stroke-linejoin="round"
                                stroke-linecap="round"
                                stroke-width="2"
                                stroke="#fff"
                                fill="transparent"
                                data-name="Path 5498"
                            />
                        </g>
                    </g>
                </svg>
            </button>
        </div>

        <div class="modal-body">
            <div class="row">
                <div class="col-md-12 mb-3 form-styles">
                    <label for="typeahead-basic">Tariff Type</label>
                    <input
                        id="typeahead-basic"
                        type="text"
                        class="form-control"
                        formControlName="type"
                        maxlength="50"
                    />
                        <small
                            class="text-danger"
                            *ngIf=" editTariffTypeForm.get('type').hasError('required') && editTariffTypeForm.get('type').touched"
                            >Please Enter Tariff Type
                        </small>
                        <small  class="text-danger" *ngIf="!editTariffTypeForm.get('type').hasError('required') &&
                        editTariffTypeForm.get('type').hasError('leadingWhitespace') &&                       
                        editTariffTypeForm.get('type').touched">
                            Tariff Type cannot be empty.
                        </small>
                        <small class="text-danger" *ngIf="editTariffTypeForm.get('type').errors?.invalidInput">
                            Type cannot be whitespace or only numbers.
                        </small>
                        <small class="text-danger" *ngIf="editTariffTypeForm.get('type').errors?.pattern">
                            Type can only contain alphabetic characters.
                        </small>
                </div>
            </div>
        </div>
        <div class="modal-footer no-bg">
            <button
                type="button"
                class="btn btn-outline-dark"
                aria-label="Close"
                (click)="d('Cross click'); resetForm()"
            >
                Cancel
            </button>
            <button
                type="button"
                class="btn btn-orange"
                [disabled]="editTariffTypeForm.invalid"
                (click)="d('Cross click')"
                type="submit"
            >
                Save
            </button>
        </div>
    </form>
</ng-template>
