<section class="dashboard-main container-fluid">
    <div class="row mb-4 pt-4 block-topbar">
        <div class="col-12">
            <!-- <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 class="mb-sm-0">Add Ticket </h4>
            </div> -->
            <h4 class="mb-sm-0">Add Ticket </h4>
        </div>
    </div>

    <div class="contents-main">
        <div class="card p-5">
            <form class="form-styles" [formGroup]="addTocketForm" (ngSubmit)="submitAddTicketForm()">
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="exampleFormControlInput1">Name </label>
                            <input type="text" formControlName="name" class="form-control" id="exampleFormControlInput1" placeholder="Enter Name">
                            <small class="text-danger" *ngIf="
                            !addTocketForm?.get('name').valid &&
                            addTocketForm?.get('name').touched
                            ">Please Enter Name</small>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="exampleFormControlInput1">Plant</label>
                            <ng-select formControlName="plantName" >
                            <ng-option *ngFor="let plantitem of plantitem" [value]="plantitem.getStationId()">{{plantitem.getPlantName()}}</ng-option>
                            </ng-select>
                            <small class="text-danger" *ngIf="
                            !addTocketForm?.get('plantName').valid &&
                            addTocketForm?.get('plantName').touched
                        ">Please Enter Plant</small>

                    <!--        
                        <label for="choices-single-default" class="form-label text-muted">Plant</label>
                        <select class="form-control" data-choices name="choices-single-default" formControlName="plantName"
                        id="choices-single-default">
                        <option value="">Plant Name</option>

                            <option *ngFor="let plantitem of plantitem" [value]="plantitem.getStationId()"  >{{plantitem.getPlantName()}}</option>

                        </select>
                        <small class="text-danger" *ngIf="
                        !addTocketForm?.get('plantName').valid &&
                        addTocketForm?.get('plantName').touched
                    ">Please Enter Plant</small> -->



                        </div>
                    </div>
                
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="exampleFormControlInput1">Priority</label>
                            <ng-select  formControlName="priorityName">
                            <ng-option *ngFor="let priorityItem of priority" [value]="priorityItem.getid()">{{priorityItem.getName()}}</ng-option>
                        </ng-select> 
                        <!-- <label for="choices-single-default" class="form-label text-muted">Priority</label>
                        <select class="form-control" data-choices name="choices-single-default" formControlName="priorityName"
                        id="choices-single-default">
                        <option value="">Priority Name</option>
                    
                            <option *ngFor="let priorityItem of priority" [value]="priorityItem.getid()" >{{priorityItem.getName()}}</option>
                    
                        </select>  -->
                        <small class="text-danger" *ngIf="
                        !addTocketForm?.get('priorityName').valid &&
                        addTocketForm?.get('priorityName').touched
                    ">Please Enter Priority</small>        
                        
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="exampleFormControlInput1" >Source</label>
                            <ng-select  formControlName="sourceName"  (change)="selctCategoryBySourceID($event)">
                            <ng-option *ngFor="let sourceItem of source" [value]="sourceItem.getId()">{{sourceItem.getName()}}</ng-option>
                        </ng-select>   
                        <small class="text-danger" *ngIf="
                        !addTocketForm?.get('sourceName').valid &&
                        addTocketForm?.get('sourceName').touched
                    ">Please Enter Source</small>  
                        
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="exampleFormControlInput1" >Category</label>
                            <ng-select  formControlName="catName"   (change)="selctSubCategoryByCategoryID($event)">
                            <ng-option *ngFor="let catItem of categoryList" [value]="catItem.getId()">{{catItem.getName()}}</ng-option>
                        </ng-select> 
                        <small class="text-danger" *ngIf="
                        !addTocketForm?.get('catName')?.valid &&
                        addTocketForm?.get('catName')?.touched
                    ">Please Enter Category</small>  
                        
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="exampleFormControlInput1">Sub Category</label>
                            <ng-select  formControlName="subcatName" >
                            <ng-option *ngFor="let subCatItem of getSubcat" [value]="subCatItem.getId()">{{subCatItem.getSubCategoryName()}}</ng-option>
                        </ng-select> 
                        <small class="text-danger" *ngIf="
                        !addTocketForm?.get('subcatName')?.valid &&
                        addTocketForm?.get('subcatName')?.touched
                    ">Please Enter Sub Category</small>  
                        </div>
                    </div>
                
                
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="exampleFormControlInput1">Assign To </label>
                            <ng-select  formControlName="assignTo">
                            <ng-option *ngFor="let car of user" [value]="car.getUserId()">{{car.getusername()}}</ng-option>
                        </ng-select>    
                        <small class="text-danger" *ngIf="
                        !addTocketForm?.get('assignTo').valid &&
                        addTocketForm?.get('assignTo').touched
                    ">Please Enter Assign To</small>       
                        </div>
                    </div>
                
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="exampleFormControlInput1">  Contact </label>
                            <input type="number" class="form-control" formControlName="contact" id="exampleFormControlInput1" placeholder="0000-0000000">
                            <small class="text-danger" *ngIf="
                            !addTocketForm?.get('contact').valid &&
                            addTocketForm?.get('contact').touched
                        ">Please Enter Contact</small> 
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="exampleFormControlInput1">Title </label>
                            <input type="text" class="form-control" formControlName="titleName" id="exampleFormControlInput1" placeholder="Enter Title">
                            <small class="text-danger" *ngIf="
                            !addTocketForm?.get('titleName').valid &&
                            addTocketForm?.get('titleName').touched
                        ">Please Enter Title</small> 
                        </div>
                    </div>
                    
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="exampleFormControlTextarea1">Description </label>
                            <textarea formControlName="discription"  class="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="Enter Description"></textarea>

                            <small class="text-danger" *ngIf="
                            !addTocketForm?.get('discription').valid &&
                            addTocketForm?.get('discription').touched
                        ">Please Enter Description</small> 
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 d-flex justify-content-end">
                        <!-- <button type="submit" [disabled]="addTocketForm.invalid" class="btn btn-secondary">Submit</button> -->
                        <button type="submit" [disabled]="addTocketForm.invalid" class="btn btn-orange btn-sm btn-label waves-effect waves-light"  ><i class="ri-check-double-line
                        label-icon align-middle fs-16 me-2"></i>Submit</button>

                    </div>
                </div>
            </form>
        </div>
    </div>
</section>