import { PlantsTariff, TariffTypeAndCompany, Tariffs } from '@/Interface/tariffs';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from '@services/app.service';
import { UtilityService } from '@services/utility.service';
import { ToastrService } from 'ngx-toastr';
import { Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-tariff-rates',
  templateUrl: './tariff-rates.component.html',
  styleUrls: ['./tariff-rates.component.scss']
})
export class TariffRatesComponent implements OnInit {

  public addTariffRatesForm: FormGroup
  public editTariffRatesForm: FormGroup
  public pageSizeControl = new FormControl(10);
  pageSize: number;
  pageToGo: number = 1;
  searchElement = '';
  serialNumberArray: Array<number> = [];
  next_page: number;
  current_page: number;
  previous_page: number;
  total_pages: number;
  total_items: number;
  private latestQueryParams: any = {};
  sortColumn: string = null; 
  sortOrder: 'asc' | 'desc' = null; 
  searchSubject: Subject<string> = new Subject();
  public tariffTypeList: TariffTypeAndCompany[]=[]
  public companyList : TariffTypeAndCompany[] = []
  loader : boolean = false  
  public pageRecordOptions = [
    { value: '10', label: '10' },
    { value: '25', label: '25' },
    { value: '50', label: '50' },
    { value: '100', label: '100' },
    { value: '200', label: '200' },
  ];
  public tariffYear = []
  public tariffList:PlantsTariff[] = []
  public plantList :any = []
  public selectedId:number
  months: string[] = [
    'Jan', 'Feb', 'Mar', 'Apr',
    'May', 'Jun', 'Jul', 'Aug',
    'Sep', 'Oct', 'Nov', 'Dec'
  ];
  monthMapping = {
    'Jan': 'January',
    'Feb': 'February',
    'Mar': 'March',
    'Apr': 'April',
    'May': 'May',
    'Jun': 'June',
    'Jul': 'July',
    'Aug': 'August',
    'Sep': 'September',
    'Oct': 'October',
    'Nov': 'November',
    'Dec': 'December'
  };

  constructor(  private modalService: NgbModal,
    private _utilityService: UtilityService,
    private appService:AppService,
    private toaster :ToastrService,
    private formBuilders: FormBuilder) { 
    const currentYear = new Date().getFullYear(); 

    for (let year = 2015; year <= currentYear; year++) {
      this.tariffYear.push({ value: year, label: year });
    }
    }

  ngOnInit(): void {
    this.getTableData();
    this.getTariffTypeList();
    this.getCompanyList();

    this.addTariffRatesForm = this.formBuilders.group({
      tariff_company: [null,[Validators.required]],
      tariff_type: [null,[Validators.required]],
      year: [null,[Validators.required]],
    });
    this.months.forEach(month => {
      this.addTariffRatesForm.addControl(month, new FormControl(null, [Validators.required, Validators.pattern(/^\d+(\.\d{1,2})?$/), Validators.min(1)]));
    });
    this.yearChangeListener(this.addTariffRatesForm);

    this.editTariffRatesForm = this.formBuilders.group({
      id: [null],
      tariff_company: [null,[Validators.required]],
      tariff_type: [null,[Validators.required]],
      year: [null,[Validators.required]]
    });

    this.yearChangeListener(this.editTariffRatesForm);

    this.months.forEach(month => {
      this.editTariffRatesForm.addControl(month, new FormControl(null, [Validators.required, Validators.pattern(/^\d+(\.\d{1,2})?$/), Validators.min(1)]));
    });
  }

  public selectCompanyName(selectedCompany){
    this.updateQueryParams({ tariff_company: selectedCompany });
  }
  public selectTariffType(selectedTariff){
    this.updateQueryParams({ tariff_type: selectedTariff });
  }
  public selectTariffYear(selectedYear){
    this.updateQueryParams({ year: selectedYear });
  }
  yearChangeListener(form:FormGroup) {
    form.get('year').valueChanges.subscribe((year:number) => {
      this.handleYearChange(year,form);
    });
  }

  handleYearChange(selectedYear: number, form:FormGroup) {
    const currentYear = new Date().getFullYear();
    const currentMonthIndex = new Date().getMonth();
  
    this.months.forEach((month, index) => {
      const control = form.get(month);
      if (control) {
        if (selectedYear === currentYear && index > currentMonthIndex) {
          control.disable();
          control.setValue(null)
        } else {
          control.enable();
        }
      }
    });
  }
  
  resetForm(){
    this.addTariffRatesForm.reset();
    // this.months.forEach(month => {
    //   this.addTariffRatesForm.patchValue({ [month]: 1.0 });
    // });
    this.editTariffRatesForm.reset();
  }
  
  openEditPopUp(item: PlantsTariff, contentupdate: any) {
    this.getCompanyList();
    this.getTariffTypeList();
    this.selectedId = item.id;
    this.editTariffRatesForm.patchValue( {
      id:item.id,
      tariff_company:item.tariff_company_id,
      tariff_type:item.tariff_type_id,
      year:item.year
    })
    this.months.forEach(shortMonthName => {
      const fullMonthName = this.monthMapping[shortMonthName];
      console.log('item rate month:', item.rates[fullMonthName])
      const rateValue = item.rates[fullMonthName] !== undefined && item.rates[fullMonthName] !== 0 && !isNaN(item.rates[fullMonthName]) ? item.rates[fullMonthName] : null;
      this.editTariffRatesForm.patchValue({ [shortMonthName]: rateValue });
    });
    console.log(this.editTariffRatesForm.value)
    this.modalService.open(contentupdate);
  }

  addTariffPopUp(content: any) {
    this.modalService.open(content);
    this.getCompanyList();
    this.getTariffTypeList();
  }
  viewTariffPopUp(item: PlantsTariff,viewContent: any) {
    this.selectedId = item.id;
    this.editTariffRatesForm.patchValue( {
      id:item.id,
      tariff_company:item.tariff_company,
      tariff_type:item.tariff_type,
      year:item.year
    })
    this.months.forEach(shortMonthName => {
      const fullMonthName = this.monthMapping[shortMonthName];
      const rateValue = item.rates[fullMonthName] !== undefined && item.rates[fullMonthName] !== 0  ? item.rates[fullMonthName] : null;
      this.editTariffRatesForm.patchValue({ [shortMonthName]: rateValue });
    });
    this.modalService.open(viewContent);
  }

  onAddTariffRate():void {
    if (this.addTariffRatesForm.valid) {
      const formValues = this.addTariffRatesForm.value;
      const rates = {};

      this.months.forEach(month => {
        const fullMonthName = this.monthMapping[month];
        const value = parseFloat(formValues[month]);
        rates[fullMonthName] = value === 0 || Number.isNaN(value) ? 0 : value.toFixed(2);
      });

      const payload = {
        tariff_company: formValues.tariff_company,
        tariff_type: formValues.tariff_type,
        year: formValues.year,
        rates: rates
      };

      console.log(payload);
  
      this._utilityService.addTariffRates(payload).pipe(
        catchError(error => {
          if (error.status === 400) {
            this.toaster.error(error.error.message);
            this.resetForm();
          } else {
            this.toaster.error('An error occurred. Please try again later.');
            this.resetForm();
          }
          return throwError(error);
        })).subscribe(response => {
        if(response && response.status === 201) {
          this.getTableData();
          this.resetForm();
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: response.message,
            showConfirmButton: false,
            timer: 1500
          })
        }
      })
    } else {
      console.log('Form is invalid');
      this.resetForm();
    }
  }

  onUpdateTariffRate(): void {
    if (this.editTariffRatesForm.valid) {
      Swal.fire({
        title: 'Are you sure you want to update this record?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'
      }).then((result) => {
        if (result.isConfirmed) {
          const formValues = this.editTariffRatesForm.value;
          const rates = {};
        
          this.months.forEach(month => {
            const fullMonthName = this.monthMapping[month];
            const value = parseFloat(formValues[month]);
            rates[fullMonthName] = value === 0 || Number.isNaN(value) ? 0 : value.toFixed(2);
          });
  
          const payload = {
            id: formValues.id,
            tariff_company: formValues.tariff_company,
            tariff_type: formValues.tariff_type,
            year: formValues.year,
            rates: rates
          };
  
          console.log(payload);
          this._utilityService.editTariffRates(payload).pipe(
            catchError(error => {
              if (error.status === 400) {
                this.toaster.error(error.error.message);
                this.resetForm();
              } else {
                this.toaster.error('An error occurred. Please try again later.');
                this.resetForm();
              }
              return throwError(error);
            })).subscribe(response => {
            if (response && (response.status === 200 || response.status === 201)) {
              this.getTableData();
              this.resetForm();
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: response.message,
                showConfirmButton: false,
                timer: 1500
              });
            }
          });
        }
      });
    } else {
      this.toaster.error('Invalid Form Data');
      this.resetForm();
    }
  }
  

  private updateQueryParams(paramsToUpdate: any): void {
    this.latestQueryParams = {
        ...this.latestQueryParams,
        ...paramsToUpdate
    };
    console.log('Updated queryParams:', this.latestQueryParams);

    if (this.latestQueryParams.search === '') {
        this.latestQueryParams.search = null;
    }
    if (this.latestQueryParams.page === 1) {
        this.latestQueryParams.page = null;
    }
    
    if (this.latestQueryParams.size === 10) {
        this.latestQueryParams.size = null;
    }
    if (this.latestQueryParams.size === 10) {
        this.latestQueryParams.size = null;
    }
    if (this.latestQueryParams.sort === null) {
        delete this.latestQueryParams.sort;
    }
    
    if (this.latestQueryParams.column === null) {
        delete this.latestQueryParams.column;
    }
    if(this.latestQueryParams.tariff_company === null){
      delete this.latestQueryParams.tariff_company;
    }
    if(this.latestQueryParams.tariff_type === null){
      delete this.latestQueryParams.tariff_type;
    }
    if(this.latestQueryParams.year === null){
      delete this.latestQueryParams.year;
    }

    if (this.latestQueryParams.page === 1 || (this.latestQueryParams.page && (this.latestQueryParams.search 
      || this.latestQueryParams.tariff_company || this.latestQueryParams.tariff_type || this.latestQueryParams.year))) {
        if (this.latestQueryParams.page > 1) {
            this.latestQueryParams.page = paramsToUpdate.page;
            if (this.latestQueryParams.size > 10) {
                this.latestQueryParams.size = paramsToUpdate.size;
            }
        } else {
            this.latestQueryParams.page = null;
        }
    } 
    // if (!this.next_page && (paramsToUpdate.size || paramsToUpdate.search)) {
    //     this.latestQueryParams = {
    //         size: paramsToUpdate.size || null,
    //         search: paramsToUpdate.search || null
    //     };
    // }
    this.getTableData(); 
}

private getTableData(): void {
    const name = this.latestQueryParams['search'] || this.searchElement;
    const page = this.latestQueryParams['page'] && this.next_page !==null ? +this.latestQueryParams['page'] : 1;
    const size = this.latestQueryParams['size'] ? +this.latestQueryParams['size'] : 10;
    const sort = this.latestQueryParams['sort'] || null;
    const column = this.latestQueryParams['column'] || null;
    const tariff_company = this.latestQueryParams['tariff_company'] || null;
    const tariff_type = this.latestQueryParams['tariff_type'] || null;
    const year = this.latestQueryParams['year'] || null;
    this.getAllTariffRates(name, page, size,sort,column,tariff_company,tariff_type,year);
}


getAllTariffRates(name: string, page: number, size: number,sort:string | null ,column :string | null,tariff_company:number | null,tariff_type:number | null,year:number | null): void {
    this.loader = true;
    this.tariffList = [];
    this.serialNumberArray = [];
    this._utilityService
        .receiveAllTariffRates(name, page, size,sort,column,tariff_company,tariff_type,year)
        .subscribe((res: any) => {
            if (res && res['status'] === 200) {
                this.tariffList = res.data;
                this.tariffList = res.data.map((tariff: any, index: number) => ({
                    ...tariff,
                    srNo: (res.pagination.current_page - 1) * size + index + 1
                }));
                console.log(this.tariffList);
                this.current_page = res.pagination.current_page;
                this.next_page = res.pagination.next;
                this.previous_page = res.pagination.previous;
                this.total_pages = res.pagination.total_pages;
                this.total_items = res.pagination.total_records;
                this.enablePagination();
            }
            this.loader = false;
        });
}
public searchData(event: Event): void {
    this.searchElement = (event.target as HTMLInputElement)?.value || '';
    console.log('search Value', this.searchElement);
    this.searchSubject.next(this.searchElement);
}

  goToPage(page: number): void {
    console.log('move to page', page);
    if (page >= 1 && page <= this.total_pages) {
        this.updateQueryParams({page:page});
    }
}

navigatePage(direction: string): void {
    if (direction === 'prev' && this.current_page > 1) {
        this.goToPage(this.current_page - 1);
    } else if (direction === 'next' && this.next_page !== null) {
        this.goToPage(this.current_page + 1);
    }
}

// Add a method to navigate to the entered page
navigateToPage(): void {
    if (this.pageToGo >= 1 && this.pageToGo <= this.total_pages) {
        this.goToPage(this.pageToGo);
    }
}

public changePageSize(): void {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.pageSize = this.pageSizeControl.value!;
    this.updateQueryParams({size:this.pageSize})
    }

    enablePagination() {
      const linkElement = document.getElementById('prevPage');

      if (this.previous_page === null) {
          linkElement.setAttribute('disabled', 'true');
          linkElement.style.pointerEvents = 'none';
          linkElement.style.color = 'gray'; 
          linkElement.addEventListener('click', (event) => {
              event.preventDefault(); 
          });
      } else {
          linkElement.removeAttribute('disabled');
          linkElement.style.pointerEvents = 'auto';
          linkElement.style.color = ''; 
          linkElement.removeEventListener('click', (event) => {
              event.preventDefault();
          });
      }
  }
  getTariffTypeList(){
    this._utilityService.receivedropdownTraiffTypes().subscribe(res=>{
      if( res && res.status ===200 ){
        if(res.data!==null){
          this.tariffTypeList = res.data
        }
      }
    })
  }
  getCompanyList(){
    this.appService.receivedropdownCompanyTariff().subscribe(res=>{
      if(res && res.status === 200) {
        if(res.data!==null){
          this.companyList = res.data
        }
      }
    })
  }

  deleteTariffRate(tariff_id:number) {
    Swal.fire({
      title: 'Are you sure you want to delete Tariff Rate?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this._utilityService.deleteTariffRates(tariff_id).subscribe(res => {
          if (res && res.status === 204) {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: res.message,
              showConfirmButton: false,
              timer: 1500
            })
            this.getTableData();
          } 
          else {
            this.toaster.error(res.message)
          }
        }, error => {
          this.toaster.error(error.error.message)
        });
      }
    });
  }


}
