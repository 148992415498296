import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class PredictedRoiService {
  private storageKey = 'prediction-form-state';

  constructor() { }

  setFormState(formState: any) {
    localStorage.setItem(this.storageKey, JSON.stringify(formState));
  }


  getFormState() {
    const storedState = localStorage.getItem(this.storageKey);
    return storedState ? JSON.parse(storedState) : null;
  }
  
}
