import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {retry} from 'rxjs-compat/operator/retry';
import {ApiService} from './api.service';
import {AppService} from './app.service';
import jwtDecode from 'jwt-decode';

@Injectable({
    providedIn: 'root'
})
export class UtilityService {
    private product$ = new BehaviorSubject<any>({});
    selectedProduct$ = this.product$.asObservable();

    private selectedPlantNameSubject = new BehaviorSubject<string>('');
    selectedPlantName$: Observable<string> = this.selectedPlantNameSubject.asObservable();
    updateSelectedPlantName(plantName: string) {
        this.selectedPlantNameSubject.next(plantName);
      }

    constructor(private _apiService: AppService) {}
    getTokan: [] = [];
    setTokan: string;

    private changeurl = new BehaviorSubject(null);
    setUrlactve = this.changeurl.asObservable();

    private SendDevId = new BehaviorSubject(null);
    receiveDevid = this.SendDevId.asObservable();

    private stationid = new BehaviorSubject(null);
    receivestationid = this.stationid.asObservable();

    setUrl(message: boolean) {
        this.changeurl.next(message);
    }
    sendDeviD(selectedNumber: number) {
        this.SendDevId.next(selectedNumber);
    }
    sendDeviD2(getStationCode: number) {
        this.stationid.next(getStationCode);
    }
    setUserInfo(username, password): Observable<any> {
        return this._apiService.setUserInfoApi(username, password);
    }
    setWeatherUpdates(getStationCode): Observable<any> {
        return this._apiService.setWeatherUpdatesApi(getStationCode);
    }
    getpowerFlowList(getStationCode): Observable<any> {
        return this._apiService.getpowerFlowListApi(getStationCode);
    }

    getHeatMapList(getStationCode): Observable<any> {
        return this._apiService.getHeatMapListApi(getStationCode);
    }
    getEnvBnifits(getStationCode, dt, getDayRang): Observable<any> {
        return this._apiService.getEnvBnifitsApi(
            getStationCode,
            dt,
            getDayRang
        );
    }
    getMonthChart(getStationCode, dt): Observable<any> {
        return this._apiService.getMonthChartApi(getStationCode, dt);
    }
    setChangePassword(ChangePassword): Observable<any> {
        return this._apiService.setChangePasswordApi(ChangePassword);
    }
    getyearChart(getStationCode, dt): Observable<any> {
        return this._apiService.getYearChartApi(getStationCode, dt);
    }
    // new added chart tab
    getliftTimeChart(getStationCode): Observable<any> {
        return this._apiService.getLiftTimeChartApi(getStationCode);
    }

    setPlantDetailList(getStationCode): Observable<any> {
        return this._apiService.setgetPlantDetailsApi(getStationCode);
    }
    countAlertlist(getStationCode): Observable<any> {
        return this._apiService.countAlertlistApi(getStationCode);
    }

    newPvGraphsList(devId, dt : Date): Observable<any> {
        if (dt == null)
            dt = new Date();
        return this._apiService.newPvGraphsListApi(devId, dt);
    }

    GetAlarmStatus() : Observable<any> {
        return this._apiService.GetAlarmStatus();
    }

    GetPlantAlarmCount(StationCode : any) : Observable<any> {
        return this._apiService.GetPlantAlarmCountAPI(StationCode);
    }
    monthlyRoiGraph(StationCode:any):Observable<any> {
        return this._apiService.getMonthRoiGraph(StationCode);
    }
    getYearlyRoiGraph(StationCode:any):Observable<any> { 
        return this._apiService.getYearlyRoiGraph(StationCode);

    }

    
    setProduct(stationCode: any) {
        this.product$.next(stationCode);
    }

    setfiveMinData(getStationCode): Observable<any> {
        return this._apiService.setfiveMinDataApi(getStationCode);
    }
    setfiveMinDataWithDate(getStationCode, dtEnv): Observable<any> {
        return this._apiService.setfiveMinDataApiWithDate(
            getStationCode,
            dtEnv
        );
    }

    setEmiGraphWithDate(getStationCode, dtEnv): Observable<any> {
        return this._apiService.setEmiGraphApiWithDate(
            getStationCode,
            dtEnv
        );
    }

    sendSubCat(): Observable<any> {
        // return this._apiService.sendSubCatApi();
        return this._apiService.sendSubCatApi();
    }
    sendCat(): Observable<any> {
        // return this._apiService.sendSubCatApi();
        return this._apiService.receiveCategoryApi();
    }

    receiveCategory(): Observable<any> {
        return this._apiService.receiveCategoryApi();
    }

    receiveSource(): Observable<any> {
        return this._apiService.receiveSourceApi();
    }

    receivePreority(): Observable<any> {
        return this._apiService.receivePreorityApi();
    }

    receiveTicketList(search:string): Observable<any> {
        return this._apiService.receiveTicketListApi(search);
    }
    receiveAllTicketList(name:string,page:number,size:number,sort:string,column:string): Observable<any> {
        return this._apiService.receiveAllTicketListApi(name,page,size,sort,column);
    }

    receiveFilteredTicketList(filter: string): Observable<any> {
        return this._apiService.FilterRecvieTicketListApi(filter);
    }

    receiveUser(): Observable<any> {
        return this._apiService.receiveUserApi();
    }
    receiveAllUsers(name:string,page:number,size:number,sort:string | null,column:string | null,user_type:number){
        return this._apiService.receiveAllUsers(name,page,size,sort,column,user_type);
    }
    receiveInactiveUsers(name:string,page:number,size:number,sort:string | null,column:string | null,user_type:number){
        return this._apiService.receiveInactiveUsers(name,page,size,sort,column,user_type);
    }
    receiveAllTraiffTypes(name:string,page:number,size:number,sort:string | null,column:string | null){
        return this._apiService.receiveAllTraiffTypes(name,page,size,sort,column);
    }
    addTariffTypes(requestBody:any):Observable<any>{
        return this._apiService.addTariffTypes(requestBody);
    }
    editTariffTypes(requestBody:any):Observable<any>{
        return this._apiService.editTariffTypes(requestBody);
    }
    deleteTariffTypes(requestBody:any):Observable<any>{
        return this._apiService.deleteTariffTypes(requestBody);
    }
    receiveAllTraiffCompanies(name:string,page:number,size:number,sort:string | null,column:string | null){
        return this._apiService.receiveAllTraiffCompanies(name,page,size,sort,column);
    }
    addTariffCompany(requestBody:any):Observable<any>{
        return this._apiService.addTariffCompany(requestBody);
    }
    editTariffCompany(requestBody:any):Observable<any>{
        return this._apiService.editTariffCompany(requestBody);
    }
    deleteTariffCompany(requestBody:any):Observable<any>{
        return this._apiService.deleteTariffCompany(requestBody);
    }
    receiveAllPlantTariffs(name:string,page:number,size:number,sort:string | null,column:string | null,tariff_company:number | null,tariff_type:number | null,plant_id:number | null){
        return this._apiService.receiveAllPlantTariffs(name,page,size,sort,column,tariff_company,tariff_type,plant_id);
    }

    receivedropdownTraiffTypes(){
        return this._apiService.receivedropdownTraiffTypes();
    }

    receiveAllTariffRates(name:string,page:number,size:number,sort:string | null,column:string | null,tariff_company:number | null,tariff_type:number | null,year:number | null){
        return this._apiService.receiveAllTariffRates(name,page,size,sort,column,tariff_company,tariff_type,year);
    }
    addTariffRates(requestBody:any):Observable<any>{
        return this._apiService.addTariffRates(requestBody);
    }
    editTariffRates(requestBody:any):Observable<any>{
        return this._apiService.editTariffRates(requestBody);
    }
    deleteTariffRates(requestBody:any):Observable<any>{
        return this._apiService.deleteTariffRates(requestBody);
    }
    
    receiveUserFilter(filter : string): Observable<any> {
        return this._apiService.FilterreceiveUserApi(filter);
    }

    sendForgot(forgotPassword): Observable<any> {
        return this._apiService.sendForgotApi(forgotPassword);
    }
    receiveNocUser(): Observable<any> {
        return this._apiService.receiveNocUserApi();
    }
    receiveSourceName(): Observable<any> {
        return this._apiService.receiveSourceApi();
    }
    selectCategoryRow(sendIdCat): Observable<any> {
        return this._apiService.selectCategoryRowApi(sendIdCat);
    }
    saveUser(userList): Observable<any> {
        return this._apiService.saveUserApi(userList);
    }

    updateCategory(categoryEdit): Observable<any> {
        return this._apiService.categoryEditApi(categoryEdit);
    }
    deleteCategory(id): Observable<any> {
        return this._apiService.deleteCategoryApi(id);
    }

    addSubCat(getSubcatadd): Observable<any> {
        return this._apiService.addSubCatApi(getSubcatadd);
    }

    receiveSubCatDate(catid): Observable<any> {
        return this._apiService.receiveSubCatDateaPI(catid);
    }
    deleteSubCategory(deleteSubCat): Observable<any> {
        return this._apiService.deleteSubCategoryApi(deleteSubCat);
    }
    getPriorityUpdate(getPriorityId): Observable<any> {
        return this._apiService.getPriorityUpdateApi(getPriorityId);
    }
    addPriority(priAdd): Observable<any> {
        return this._apiService.addPriorityApi(priAdd);
    }
    updatePriorityList(priUpdateModel): Observable<any> {
        return this._apiService.updatePriorityListApi(priUpdateModel);
    }
    deletePriortyList(deletePri): Observable<any> {
        return this._apiService.deletePriortyListApi(deletePri);
    }
    deleteSourceList(deleteSource): Observable<any> {
        return this._apiService.deleteSourceListApi(deleteSource);
    }
    addSourceList(addSourceModel): Observable<any> {
        return this._apiService.addSourceListApi(addSourceModel);
    }
    getSourceByid(getUpdateId): Observable<any> {
        return this._apiService.getSourceByidApi(getUpdateId);
    }
    updateSourceList(updateSourceModel): Observable<any> {
        return this._apiService.updateSourceListApi(updateSourceModel);
    }
    getPlantsList(): Observable<any> {
        return this._apiService.getPlantsListApi();
    }
    addComplainList(addComplan): Observable<any> {
        return this._apiService.addComplainListApi(addComplan);
    }
    getCategoryBySourceId(sourceId): Observable<any> {
        return this._apiService.getCategoryBySourceIdApi(sourceId);
    }
    getSubCategoryByCatId(catId): Observable<any> {
        return this._apiService.getSubCategoryByCatIdApi(catId);
    }
    changeStatus(priortylist): Observable<any> {
        return this._apiService.changeStatusApi(priortylist);
    }
    changePrioritySelect(changePrioritySelect): Observable<any> {
        return this._apiService.changePrioritySelectApi(changePrioritySelect);
    }
    changeAssignToSend(selectUser): Observable<any> {
        return this._apiService.changeAssignToSendApi(selectUser);
    }
    recvieTicketList(ticektId): Observable<any> {
        return this._apiService.recvieTicketListApi(ticektId);
    }
    receiveAlarm(): Observable<any> {
        return this._apiService.receiveAlarmApi();
    }
    receiveAllAlarm(name:string,page:number,size:number,sort:string | null , column:string | null): Observable<any> {
        return this._apiService.receiveAllAlarmApi(name,page,size,sort,column);
    }
    receiveHistoricalAlarm(name:string,page:number,size:number,sort:string|null,column:string |null): Observable<any> {
        return this._apiService.receiveHistoricalAlarm(name,page,size,sort,column);
    }
    clearAlarms(body:any): Observable<any> {
        return this._apiService.clearAlarms(body);
    }
    unClearAlarms(body:any): Observable<any> {
        return this._apiService.unClearAlarms(body);
    }

    receiveAlarmFilter(filter : string): Observable<any> {
        return this._apiService.receiveAlarmApiFilter(filter);
    }

    receiveAlarmbyID(getAlarm): Observable<any> {
        return this._apiService.receiveAlarmbyIDApi(getAlarm);
    }
    decodeUserCode() {
        this.setTokan = localStorage.getItem('tokan');
        if (this.setTokan) {
            return (this.getTokan = jwtDecode(this.setTokan));
        }
    }
    PlantsdetailsAll(): Observable<any> {
        return this._apiService.PlantsdetailsAllApi();
    }
    selectUserType(): Observable<any> {
        return this._apiService.selectUserTypeAPI();
    }
    linkUserPlants(LinkPlantU): Observable<any> {
        return this._apiService.linkUserPlantsApi(LinkPlantU);
    }
    showLinkPlant(userInlist): Observable<any> {
        return this._apiService.showLinkPlantApi(userInlist);
    }
    removeUserPlant(removeLinkPlantList): Observable<any> {
        return this._apiService.removeUserPlantApi(removeLinkPlantList);
    }
    chanePassword(changePass): Observable<any> {
        return this._apiService.chanePasswordApi(changePass);
    }

    inverterPrimeData(getStationCode): Observable<any> {
        return this._apiService.inverterPrimeDataApi(getStationCode);
    }
    pvgetData(getStationCode): Observable<any> {
        return this._apiService.pvgetDataApi(getStationCode);
    }

    setInverterDcGr(stData, setDate): Observable<any> {
        return this._apiService.setInverterDcGrApi(stData, setDate);
    }
    setNormalizdGraph(receivestationResult, setDate): Observable<any> {
        return this._apiService.setNormalizdGraphApi(
            receivestationResult,
            setDate
        );
    }
    setInverterOPGr(stData, setDate): Observable<any> {
        return this._apiService.setInverterOPGrApi(stData, setDate);
    }
    setInverterNOmalPGr(stData): Observable<any> {
        return this._apiService.setInverterNOmalPGraPI(stData);
    }


    getPvVoltageGraph(stData, setDate): Observable<any> {
        return this._apiService.getPvVoltageGraphApi(stData, setDate);
    }
    getPVcurrentValues(stData, setDate): Observable<any> {
        return this._apiService.getPVcurrentValuesApi(stData, setDate);
    }
    getPVPowerValues(stData, setDate): Observable<any> {
        return this._apiService.getPVPowerValuesApi(stData, setDate);
    }
    getBothVoltageCurrentsend(stData, setDate): Observable<any> {
        return this._apiService.getBothVoltageCurrentsendApi(stData, setDate);
    }
    getBothVoltagePowersend(stData, setDate): Observable<any> {
        return this._apiService.getBothVoltagePowersendApi(stData, setDate);
    }
    getBothCurrentPowersend(stData, setDate): Observable<any> {
        return this._apiService.getBothCurrentPowersendApi(stData, setDate);
    }

    updateInstallationCost(stationCode, installationCost): Observable<any> {
        return this._apiService.updateInstallationCostApi(stationCode,installationCost);
    }
    reCalculateRoi(stationCode): Observable<any> {
        return this._apiService.reCalculateRoiApi(stationCode);
    }

    predictedRoiApi(requestBody:any): Observable<any> {
        return this._apiService.predictedRoiApi(requestBody);
    }


    getCommentsApi(ticketId): Observable<any> {
        return this._apiService.getCommentsApi(ticketId);
    }
    
    addCommentsApi(ticketId, comment, files, type): Observable<any> {
        return this._apiService.addCommentsApi(ticketId, comment, files, type);
    }

    disableUserApi(userId):Observable<any> {
        return this._apiService.disableUserApi(userId);
    }
    enableUserApi(userId):Observable<any> {
        return this._apiService.enableUserApi(userId);
    }

    getUserProfileAPI():Observable<any> {
        return this._apiService.getUserProfile(localStorage.getItem('userId'));
    }

    updateUserProfileAPI(userProfile : any):Observable<any> {
        return this._apiService.updateUserProfile(localStorage.getItem('userId'), userProfile);
    }
}
