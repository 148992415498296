import {Alarm} from '@/Interface/alarm';
  import {Component, OnInit, ViewChild} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
  import { ActivatedRoute, Router } from '@angular/router';
  import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
  import {UtilityService} from '@services/utility.service';
  import {Subject} from 'rxjs';

@Component({
  selector: 'app-historical-alarms',
  templateUrl: './historical-alarms.component.html',
  styleUrls: ['./historical-alarms.component.scss']
})
export class HistoricalAlarmsComponent implements OnInit {
  
      @ViewChild('search', {static: false}) search: any;
      plantName: any;
      stationName: any;
      filteredRow: any[] = [];
      esnCode: any;
      devName: any;
      loader: boolean;
      dtOptions: DataTables.Settings = {};
      @ViewChild('myTable') table: any;
      rows: any[] = [];
      pageSize:number;
      searchElement="";
      selectedRowIds: number[] = [];
      columnCheckboxSelected:boolean
      clearAllButtonDisabled: boolean = true;
      serialNumberArray: Array<number> = [];
      private latestQueryParams: any = {};
      sortColumn: string = null; 
      sortOrder: 'asc' | 'desc' = null; 
      pageToGo: number = 1;



  
      alarmList: Alarm;
      alarmDetailsItem: Alarm;
    //   row: Alarm[] = [];
    row: Alarm | any;
      alarmInfo: Alarm;
      dtTrigger: Subject<any> = new Subject<any>();
      public pageSizeControl = new FormControl(10);
    public pageRecordOptions = [
            { value: '10', label: '10' },
            { value: '25', label: '25' },
            { value: '50', label: '50' },
            { value: '100', label: '100' }
        ];
  
      constructor(
          private _utiltiySevice: UtilityService,
          private modalService: NgbModal,
          private activatedRoute:ActivatedRoute,
          private router:Router,
          private _snackBar:MatSnackBar
      ) {}
  
      ngOnInit(): void {
        //   this.getAlarm();
        this.getTableData();
      }
  
    //   private updateQueryParams(paramsToUpdate: any): void {
    //     const queryParams = {
    //         ...this.activatedRoute.snapshot.queryParams,
    //         ...paramsToUpdate
    //     };
    //     console.log('Updated queryParams:', queryParams);

    //     if (queryParams.search === '') {
    //         queryParams.search = null;
    //     }
    //     if (queryParams.page === 1) {
    //         queryParams.page = null;
    //     }
        
    //     if (queryParams.size === 10) {
    //         queryParams.size = null;
    //     }
    //     this.router
    //         .navigate([], {
    //             relativeTo: this.activatedRoute,
    //             queryParams: queryParams,
    //             queryParamsHandling: 'merge'
    //         })
    //         .then(() => {
    //             this.getTableData();
    //         });
    // }

    sortAlarms(column: string): void {
        if (this.sortColumn === column) {
          this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortColumn = column;
          this.sortOrder = 'asc';
        }
      
        let sortValue: string | null = null;
        let columnName: string;
      
        if (this.sortOrder === 'asc') {
          sortValue = '0';
        } else if (this.sortOrder === 'desc') {
          sortValue = '1';
        }
      
        // Mapping column names used in the template to the corresponding column names expected by the API
        switch (column) {
          case 'alarmName':
            columnName = 'alarmName';
            break;
          case 'devName':
            columnName = 'devName';
            break;
          default:
            columnName = column;
            break;
        }
      
        this.updateQueryParams({ sort: sortValue, column: columnName });
      }

    onActivate(event: any, ticketDetail: any): void {
        if (event.type === 'click' && event.row) {
          // Check if the click target is within the checkbox, clear button, or their child elements
          const targetClassList = Array.from(event.event.target.classList);
          if (targetClassList.includes('custom-checkbox') || targetClassList.includes('alaramCleareButton') || targetClassList.includes('cross-btn') || targetClassList.includes('svg')) {
            return; // Prevent opening the modal
          }
      
          // Check if the click target is a child element of the checkbox or clear button
          const isChildOfCheckbox = event.event.target.closest('.custom-checkbox');
          const isChildOfClearButton = event.event.target.closest('.alaramCleareButton');
          if (isChildOfCheckbox || isChildOfClearButton) {
            return; // Prevent opening the modal
          }
      
          // Open the modal for other cases
          this.showTicketList(event.row, ticketDetail);
        }
      }
    // private getTableData(): void {
    //     const name =
    //         this.activatedRoute.snapshot.queryParams['search'] ||
    //         this.searchElement;
    //     console.log('name search', name);
    //     this.current_page = this.activatedRoute.snapshot.queryParams['page']
    //         ? +this.activatedRoute.snapshot.queryParams['page']
    //         : 1;
    //     console.log('current Page', this.current_page);
    //     this.pageSize = this.activatedRoute.snapshot.queryParams['size']
    //         ? +this.activatedRoute.snapshot.queryParams['size']
    //         : 10;
    //     this.getHistoricalAlarm(name, this.current_page, this.pageSize);
    // }

    private updateQueryParams(paramsToUpdate: any): void {
        this.latestQueryParams = {
            ...this.latestQueryParams,
            ...paramsToUpdate
        };
        console.log('Updated queryParams:', this.latestQueryParams);
    
        if (this.latestQueryParams.search === '') {
            this.latestQueryParams.search = null;
        }
        if (this.latestQueryParams.page === 1) {
            this.latestQueryParams.page = null;
        }
        
        if (this.latestQueryParams.size === 10) {
            this.latestQueryParams.size = null;
        }
        if (this.latestQueryParams.sort === null) {
            delete this.latestQueryParams.sort;
        }
        
        if (this.latestQueryParams.column === null) {
            delete this.latestQueryParams.column;
        }
        if (this.latestQueryParams.page === 1 || (this.latestQueryParams.page && (this.latestQueryParams.search || this.latestQueryParams.department_id || this.latestQueryParams.designation_id))) {
            if (this.latestQueryParams.page > 1) {
                this.latestQueryParams.page = paramsToUpdate.page;
                if (this.latestQueryParams.size > 10) {
                    this.latestQueryParams.size = paramsToUpdate.size;
                }
            } else {
                this.latestQueryParams.page = null;
            }
        }  
        if (!this.next_page && (paramsToUpdate.size || paramsToUpdate.search)) {
            // Only include size or search in the query params
            this.latestQueryParams = {
                size: paramsToUpdate.size || null,
                search: paramsToUpdate.search || null
            };
        }
    
    
        this.getTableData(); 
    }
    
    private getTableData(): void {
        const name = this.latestQueryParams['search'] || this.searchElement;
        console.log('name search', name);
        const page = this.latestQueryParams['page'] ? +this.latestQueryParams['page'] : 1;
        console.log('current Page', page);
        const size = this.latestQueryParams['size'] ? +this.latestQueryParams['size'] : 10;
        const sort = this.latestQueryParams['sort'] || null;
        const column = this.latestQueryParams['column'] || null;
        this.getHistoricalAlarm(name, page, size,sort,column);
    }

    getHistoricalAlarm(name: string, page: number, size: number,sort:string | null , column:string | null): void {
        this.loader = true;
        this.row = [];
        this.serialNumberArray = [];
        this._utiltiySevice
            .receiveHistoricalAlarm(name, page, size,sort,column)
            .subscribe((res: any) => {
                if (res && res['status'] === 200) {
                    console.log('Alarms list:', res.data)
                    console.log(res.data);
                    this.row = res.data;
                    this.row = res.data.map(item => ({ ...item, selected: false, clearButtonDisabled: true}));
                    this.current_page = res.pagination.page_number;
                    this.next_page = res.pagination.next_page;
                    this.previous_page = res.pagination.previous_page;
                    this.total_pages = res.pagination.total_pages;
                    this.total_items = res.pagination.total_records;
                    this.enablePagination();
                }
                this.loader = false;
            });
    }
    public searchData(event: Event): void {
        this.searchElement = (event.target as HTMLInputElement)?.value || '';
        console.log('search Value', this.searchElement);

        if (this.searchElement.trim() === '') {
            this.updateQueryParams({search: null}); 
        } else {
            this.updateQueryParams({search: this.searchElement});
        }
    }

    goToPage(page: number): void {
        console.log('move to page', page);
        if (page >= 1 && page <= this.total_pages) {
            this.updateQueryParams({page});
        }
    }

    navigatePage(direction: string): void {
        if (direction === 'prev' && this.current_page > 1) {
            this.goToPage(this.current_page - 1);
        } else if (direction === 'next' && this.next_page !== null) {
            this.goToPage(this.current_page + 1);
        }
    }
    
// Add a method to navigate to the entered page
    navigateToPage(): void {
        if (this.pageToGo >= 1 && this.pageToGo <= this.total_pages) {
            this.goToPage(this.pageToGo);
        }
    }

    public changePageSize(): void {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.pageSize = this.pageSizeControl.value!;
        this.updateQueryParams({size:this.pageSize})
        }
  
      onSort(event: any): void {
          const sortBy = event.sorts[0];
  
          // Assuming your data is an array of objects (this.alarmList), use Array.prototype.sort()
          this.row.sort((a, b) => {
              const valueA = a[sortBy.prop];
              const valueB = b[sortBy.prop];
  
              if (typeof valueA === 'number' && typeof valueB === 'number') {
                  // Compare numbers directly
                  return sortBy.dir === 'asc' ? valueA - valueB : valueB - valueA;
              } else if (
                  typeof valueA === 'string' &&
                  typeof valueB === 'string'
              ) {
                  // Compare strings using localeCompare
                  return sortBy.dir === 'asc'
                      ? valueA.localeCompare(valueB)
                      : valueB.localeCompare(valueA);
              } else {
                  // Fallback to comparing as strings
                  return String(valueA).localeCompare(String(valueB));
              }
          });
      }
  
  
      getAlarm() {
          // this.activatedRoute.queryParams.subscribe(params => {
          //     this.searchElement = params['search'] || '';
          //     this.pageSize = params['size'] ? +params['size'] : 10;
          //     this.current_page = params['page'] ? +params['page'] : 1;
          // });
          this.loader = true;
        //   this._utiltiySevice.receiveHistoricalAlarm().subscribe((res) => {
        //       if (res) {
        //           console.log('search element',this.searchElement)
        //           this.row = res.data;
        //           this.loader = false;
  
        //           this.current_page = res.current_page;
        //           if (res.next_page == null) {
        //               this.next_page = res.last_page;
        //           } else {
        //               this.next_page = res.next_page;
        //           }
        //           this.previous_page = res.previous_page;
        //           this.last_page = res.last_page;
        //           this.total_pages = res.total_pages;
  
        //           this.enablePagination();
        //       }
        //   });
      }
  
      showTicketList(alarmDetails: any, ticketDetail: any) {
          //debugger;
          // alert('testing');
          this.modalService.open(ticketDetail, {
              windowClass: 'complainModelClass'
          });
          this.alarmDetailsItem = alarmDetails;
          // this.ticektId = itemTicektList.getTicketID();
  
          // this._utiltiySevice.recvieTicketList(this.ticektId).subscribe((res) => {
          //   if (res) {
          //     this.ticketListbyid = res[0];
          //      this.statusType = this.statusDDL.find(x => x.id === this.ticketListbyid.status)?.text;
          //     this.getTicketCOmments();
          //    }
          // });
      }
  
      // ------------------------------------------------------------ Ticket List -------------------------------------------------------n
      next_page: number;
      current_page: number;
      filtered_page: number;
      previous_page: number;
      last_page: number;
      total_pages: number;
      total_items:number;
  
      enablePagination() {
          const linkElement = document.getElementById('prevPage');
  
          if (this.previous_page === null) {
              linkElement.setAttribute('disabled', 'true');
              linkElement.style.pointerEvents = 'none';
              linkElement.style.color = 'gray'; // You can set the color to indicate it's disabled
              linkElement.addEventListener('click', (event) => {
                  event.preventDefault(); // Prevent the default behavior (navigation)
              });
          } else {
              linkElement.removeAttribute('disabled');
              linkElement.style.pointerEvents = 'auto'; // Enable clicking
              linkElement.style.color = ''; // Reset the color to its default
              linkElement.removeEventListener('click', (event) => {
                  event.preventDefault();
              });
          }
      }
  
      Pagination(pageNo: number) {
          //debugger;
          if (pageNo >= 1 && pageNo <= this.total_pages) {
              this.filtered_page = pageNo;
              this.filtersAlarm(this.createfilter());
          }
      }
  
      createfilter(): string {
          var filter = '';
          if (!(this.plantName === null || this.plantName == undefined)) {
              if (this.plantName !== '') {
                  filter = filter + 'search=' + this.plantName;
              }
          }
          if (this.filtered_page >= 0) {
              if (filter !== '') {
                  filter = filter + '&';
              }
              filter = filter + 'page=' + this.filtered_page;
          }
  
          // if(this.plantstatusfilter > 0)
          // {
          //     if(filter !== "")
          //     {
          //         filter = filter + '&';
          //     }
          //     filter = filter + "status=" + this.plantstatusfilter;
          // }
  
          // if(this.plantTypefilter > 0)
          // {
          //     if(filter !== "")
          //     {
          //         filter = filter + '&';
          //     }
          //     filter = filter + "type=" + this.plantTypefilter;
          // }
  
          return filter;
      }
  
      filtersAlarm(filter: string) {
          this.loader = true;
          //debugger;
          this._utiltiySevice.receiveAlarmFilter(filter).subscribe((res) => {
              if (res) {
                  //debugger;
                  this.row = res.data;
                  this.loader = false;
  
                  this.current_page = res.current_page;
                  if (res.next_page == null) {
                      this.next_page = res.last_page;
                  } else {
                      this.next_page = res.next_page;
                  }
                  this.previous_page = res.previous_page;
                  this.last_page = res.last_page;
                  this.total_pages = res.total_pages;
  
                  this.enablePagination();
              }
          });
      }
  
      // enablePagination()
      // {
      //     const linkElement = document.getElementById('prevPage');
  
      //     if (this.previous_page === null) {
      //         linkElement.setAttribute('disabled', 'true');
      //         linkElement.style.pointerEvents = 'none';
      //         linkElement.style.color = 'gray'; // You can set the color to indicate it's disabled
      //         linkElement.addEventListener('click', (event) => {
      //         event.preventDefault(); // Prevent the default behavior (navigation)
      //     });
      //     } else {
      //         linkElement.removeAttribute('disabled');
      //         linkElement.style.pointerEvents = 'auto'; // Enable clicking
      //         linkElement.style.color = ''; // Reset the color to its default
      //         linkElement.removeEventListener('click', (event) => {
      //         event.preventDefault();
      //     });
      //     }
      // }
  
      // -------------------------------------------------------------------------------------------------------------------------------
  
      onDetailToggle(event) {}
      toggleExpandRow(row) {
          this.table.rowDetail.toggleExpandRow(row);
          var getAlarm = row.id;
          this._utiltiySevice.receiveAlarmbyID(getAlarm).subscribe((res) => {
              if (res) {
                  this.alarmInfo = res.data[0];
              }
          });
      }
      // showAlarm(row: Alarm, ticketDetail: any) {
      //     this.modalService.open(ticketDetail, {
      //         windowClass: 'complainModelClass'
      //     });
      //     var getAlarm = row.id;
      //     this._utiltiySevice.receiveAlarmbyID(getAlarm).subscribe((res) => {
      //         if (res) {
      //             this.alarmInfo = res.data[0];
      //         }
      //     });
      // }
  
      // Search() {
      //     console.log('frontend Search Function')
      //     if (this.plantName == '') {
      //         this.ngOnInit();
      //     } else {
      //         this.row = this.row.filter((res) => {
      //             return res.alarmName
      //                 ?.toLocaleLowerCase()
      //                 .match(this.plantName?.toLocaleLowerCase());
      //         });
      //     }
      // }
      public Search(event: Event): void {
          this.searchElement = (event.target as HTMLInputElement)?.value || '';
          console.log('searched:', this.searchElement);
          this.filtersAlarm(this.searchElement)
        }
      SearchStationName() {
          if (this.stationName == '') {
              this.ngOnInit();
          } else {
              this.filteredRow = this.row.filter((res) =>
                  res.alarmName
                      ?.toLocaleLowerCase()
                      .includes(this.plantName?.toLocaleLowerCase())
              );
          }
      }
      SearchesnCode() {
          if (this.esnCode == '') {
              this.ngOnInit();
          } else {
              this.row = this.row.filter((res) => {
                  return res.esnCode
                      ?.toLocaleLowerCase()
                      .match(this.esnCode?.toLocaleLowerCase());
              });
          }
      }
      SearchdevName() {
          if (this.devName == '') {
              this.ngOnInit();
          } else {
              this.row = this.row.filter((res) => {
                  return res.devName
                      ?.toLocaleLowerCase()
                      .match(this.devName?.toLocaleLowerCase());
              });
          }
      }

      // Function to toggle selection for all rows
      toggleAllRows(checked: boolean): void {
        console.log('check box', checked);
        this.columnCheckboxSelected = checked;
        if (checked) {
            this.selectedRowIds = [];
            this.row.forEach((row) => {
                row.selected = true;
                this.selectedRowIds.push(row.id);
            });
            this.clearAllButtonDisabled = false;
        } else {
            this.row.forEach((row) => (row.selected = false));
            this.selectedRowIds = [];
            this.clearAllButtonDisabled = true;
        }
        console.log('Selected Row IDs:', this.selectedRowIds);
    }
    // Function to toggle selection for specific rows
    toggleRowSelection(row: any): void {
        row.selected = !row.selected;
    
        if (row.selected) {
            this.selectedRowIds.push(row.id);
        } else {
            const index = this.selectedRowIds.indexOf(row.id);
            if (index !== -1) {
                this.selectedRowIds.splice(index, 1);
            }
        }
    
        // Update clearButtonDisabled based on row selection
        row.clearButtonDisabled = !row.selected;
    
        }

// Clear Alarms 
// onUnClear():void {
//     if(this.selectedRowIds) {
//         const formData= {
//             alarm_ids:this.selectedRowIds
//         }
//         console.log(formData)
//         this._utiltiySevice.unClearAlarms(formData).subscribe((res:any)=>{
//             if(res['status']===200){
//                 console.log(res)
//                 this.getTableData();
//             }
//             else{
//                 console.log(res.message)
//             }
//         })
//     }
// }
onUnClear(): void {
    if (this.selectedRowIds && this.selectedRowIds.length > 0) {
        const formData = {
            alarm_ids: this.selectedRowIds
        };
        console.log(formData);
        this._utiltiySevice.unClearAlarms(formData).subscribe((res: any) => {
            if (res['status'] === 200) {
                console.log(res);
                this.columnCheckboxSelected = false
                this.clearAllButtonDisabled = true;
                this.selectedRowIds = []
                this.getTableData();
                this._snackBar.open(res.message, 'Close', {
                    duration: 3000, // Duration in milliseconds
                  });
            } else {
                this._snackBar.open(res.message, 'Close', {
                    duration: 3000, // Duration in milliseconds
                  });
            }
        });
    } else {
        this._snackBar.open('Please select at least one alarm to Unclear!', 'Close', {
            duration: 3000, // Snackbar duration in milliseconds
        });
    }
}
  }
  