import {Component, OnInit} from '@angular/core';
import { AppService } from '@services/app.service';

@Component({
    selector: 'app-weather-station',
    templateUrl: './weather-station.component.html',
    styleUrls: ['./weather-station.component.scss']
})
export class WeatherStationComponent implements OnInit {
    pvGraphRes: any[];
    pvGraphKey: any[];
    isCheckboxDisabled = true;
    public chartType: 'line' | 'bar' | 'doughnut' = 'line'; // Use a union type for valid chart types
    public lineChartLegend = true;
    constructor(private appService:AppService) {}

    ngOnInit(): void {}

    isCheckBoxDisabled(item): boolean {
        // if (item.status == false) {
        //     const filteredObjects = this.pvGraphKey.filter(obj => obj.status === 1 || obj.status === true);
        //     if (filteredObjects.length < 5) {
        //         return false;
        //     }
        //     return true;
        // }
        // else
        return false;
    }
    onCheckboxChange(): void {
        ////debugger;
        // Handle checkbox change event
        // this.SetGraphData();
        // You can perform additional actions based on the checkbox state here
    }

    stripIntegration() {
        const product_id = 3
        return this.appService.stripIntegration(product_id).subscribe((response:any) => {
            if(response) {
                window.location.href = response.session_url;
            }
        })
    }
}
