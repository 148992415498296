<header id="page-topbar" class="pageHeader">
    <div class="layout-width">
        <div class="header-frame">
            <form class="d-none d-md-flex"><!-- <form class="search-form d-none d-md-flex"> -->
                <!-- <input type="search" placeholder="Search here..."> -->
                <!-- <button type="submit" class="btn-search"><i><svg xmlns="http://www.w3.org/2000/svg" width="16.204" height="16.204" viewBox="0 0 16.204 16.204">
                    <g id="search-outline" transform="translate(0.5 0.5)">
                      <path id="Path_2581" data-name="Path 2581" d="M10.635,4.5a6.135,6.135,0,1,0,6.135,6.135A6.135,6.135,0,0,0,10.635,4.5Z" transform="translate(-4.5 -4.5)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="1"/>
                      <path id="Path_2582" data-name="Path 2582" d="M23.786,23.786l4.285,4.285" transform="translate(-13.074 -13.074)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1"/>
                    </g>
                  </svg>
                  </i></button> -->
            </form>
            <div class="header-meta">
                <ul class="meta-list">
                    <li style="opacity: 0.3;">
                        <a href="#">
                            <i class="ico">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24.5" height="18.5" viewBox="0 0 24.5 18.5">
                                    <g id="mail-outline" transform="translate(-3.75 -8.709)">
                                      <rect id="Rectangle_2500" data-name="Rectangle 2500" width="23" height="17" rx="3.75" transform="translate(4.5 9.459)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                      <path id="Path_5376" data-name="Path 5376" d="M10.5,15l7.871,6.122L26.242,15" transform="translate(-2.502 -2.533)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                    </g>
                                  </svg>
                                  
                                <small>10</small>                                 
                            </i>
                        </a>
                    </li>
                    <li class="notifications">
                        <a href="#" class="dropdown-toggle notification-icon" data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false">
                            <i class="ico">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18.468" height="20.6" viewBox="0 0 18.468 20.6">
                                    <path id="Path_5373" data-name="Path 5373" d="M22.76,14.494l-1.923-1.923V10.024a7.113,7.113,0,0,0-6.393-7.068V1.5H13.024V2.956a7.113,7.113,0,0,0-6.393,7.068v2.547L4.708,14.494A.71.71,0,0,0,4.5,15v2.131a.71.71,0,0,0,.71.71h4.972v.552a3.66,3.66,0,0,0,3.2,3.693,3.556,3.556,0,0,0,3.907-3.535v-.71h4.972a.71.71,0,0,0,.71-.71V15a.71.71,0,0,0-.208-.5Zm-6.9,4.054a2.131,2.131,0,1,1-4.262,0v-.71h4.262Zm5.683-2.131H5.921V15.29l1.923-1.923a.71.71,0,0,0,.208-.5V10.024a5.683,5.683,0,1,1,11.365,0v2.841a.71.71,0,0,0,.208.5l1.923,1.923Z" transform="translate(-4.5 -1.5)"/>
                                  </svg>
                                  
                                <small>{{unMarkedNotifications ? unMarkedNotifications : 0}}</small>                                 
                            </i>
                        </a>
                        <div class="dropdown-menu dropdown-menu-end notificaiton-holder">
                            <div class="topnav-dropdown-header">
                                <span class="notification-title">Notifications</span>
                                <!-- [ngClass]="{'disabled': notificationCount === 0}" (click)="notificationCount > 0 && markAsRead()" -->
                                <a href="javascript:void(0)" class="clear-noti" (click)="markAsRead()"> Mark all as read
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <path
                                            d="M14 8C14 4.6875 11.3125 2 8 2C4.6875 2 2 4.6875 2 8C2 11.3125 4.6875 14 8 14C11.3125 14 14 11.3125 14 8Z"
                                            stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" />
                                        <path d="M10.9999 5.50049L6.79988 10.5005L4.99988 8.50049" stroke="currentColor"
                                            stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </a>
                            </div>
                            <div class="noti-content">
                                <ul class="notification-list">
                                    <li class="notification-message" *ngFor="let notification of notificationList">
                                        <span class="new-noti" *ngIf="!notification.is_read"></span>
                                        <a [routerLink]="'/activities/activites-view'">
                                            <div class="chat-block d-flex">
                                                <span class="avatar flex-shrink-0">
                                                    <img alt="" src="\assets\images\users\user-dummy-img.jpg">
                                                </span>
                                                <div class="media-body flex-grow-1">
                                                    <p class="noti-details"><span class="noti-title">{{notification.participate}}</span> {{notification.notification_message}} <span class="noti-title">{{notification.ticket_name}}</span></p>
                                                    <p class="noti-time"><span class="notification-time">{{notification.created_at}}</span></p>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li>
                </ul>
                <ul class="meta-user"> 
                    <li>
                      
                        <a href="#" class="btn"
                        id="page-header-user-dropdown"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false">
                            <!-- <img src="assets/images/img-user.png" alt=""> -->
                            <img [src]="(this.appService.profileImage$ | async) || 'assets/images/img-user.png'" alt="User Profile Picture" />
                            <span class="name"><span class="d-none d-lg-block">{{authTokan.username}}</span> <i class="fa fa-chevron-down"></i></span>
                            <!-- <span class="name"><span class="d-none d-lg-block">Kashif Ahmad</span> <i class="fa fa-chevron-down"></i></span> -->
                        </a>
                        <div class="dropdown-menu dropdown-menu-end">
                            <app-user></app-user> 
                        </div>
                    </li>
                </ul>
            </div>
        </div> 
    </div>
</header>