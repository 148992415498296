import {
    Component,
    OnInit,
    OnDestroy,
    Renderer2,
    HostBinding
} from '@angular/core';
import {
    UntypedFormGroup,
    UntypedFormControl,
    Validators,
    FormBuilder,
    FormGroup
} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {AppService} from '@services/app.service';
import {ApiService} from '@services/api.service';
import {Router, ActivatedRoute} from '@angular/router';
import { NgForm, NgModel } from "@angular/forms";
import {HttpClient, HttpResponse, HttpHeaders} from '@angular/common/http';

import {login} from '@/Interface/register';
import {LoginClass} from '@/user';
import {first} from 'rxjs/internal/operators/first';
import { Login } from '../../Interface/login';
import { UtilityService } from '@services/utility.service';
import { ForgotPassword } from '@/Interface/forgotPassword';
import Swal from "sweetalert2";
import { NotificationSocketService } from '@services/websocket/notification/notification-socket.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    forgotPassword =new ForgotPassword();
    loginForm: FormGroup;
    loading = false;
    submitted: boolean = false;
    private wsSubscription: Subscription = new Subscription();
    public connectionState: boolean = false;

    returnUrl: string;
    user = new Login();
     loginFlag:boolean = true;
     forgetPasswordFlag:boolean = false;
     notificationsList = []

    error = '';
    users: login[] = [];
    toastr: any;
    userInfo:Login
    jwtTokan: string;
    userNew = new LoginClass();
    getDecodedTokan: any;
    loginError : boolean = false;
    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AppService,
        private _utilityService:UtilityService,
        private _serviceApi:ApiService,
        private _notificationService: NotificationSocketService
    ) {}
    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });

        this.loginFlag = true;
        this.forgetPasswordFlag = false;

        this.loginError = false;
       
    }
    get f() {
        return this.loginForm.controls;
    }

    navigateToForgotPassword() {
        debugger;
        // Use the Angular Router to navigate to the "Forgot Password" route
        this.router.navigate(['/forgot-password']);
      }

    saveUser(applicationForm:NgForm) {
       
        // if (this.loginForm.invalid) {
        //     return;
        // }
        this.loginError = false;
        var username = this.user.username;
        var password = this.user.password;
        this._utilityService.setUserInfo(username,password).subscribe((res)=>{
            if(res.success === true){
                this.submitted = false;
           
            this.jwtTokan = res.data.access_token;
           localStorage.setItem('tokan', this.jwtTokan);
          this.getDecodedTokan = this._serviceApi.decodeAuth2();
          //debugger;
           this.router.navigate(['/dashboard']);
            }else{
                this.submitted = true;
                this.loginError = true;
            }
        })     
    }

    hideErrorMessage() {
        this.loginError = false;
      }

    showForget(){        
        this.loginFlag = false;
        this.forgetPasswordFlag = true;
    }

    loginBack(){
        this.loginFlag = true;
        this.forgetPasswordFlag = false;
    }

    submitForget(forget:NgForm){
       this.forgotPassword.email = this.forgotPassword.email;
       this._utilityService.sendForgot(this.forgotPassword).subscribe((res)=>{
        if(res){

            if(res.message == "Email not found.")
            {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: '`<h2>This email is not found In the system.</h2>`',
                    showConfirmButton: false,
                    timer: 1500
                  });
            }
            else{
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: '`<h2>Email sent to your Email address</h2>`',
                    showConfirmButton: false,
                    timer: 1500
                  });
            }
            
              this.forgotPassword.email = "";   // to reset the value
              this.router.navigate(['/login']);
              this.loginFlag = true;
              this.forgetPasswordFlag = false;
         }
       })
    }
}
