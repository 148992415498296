<section class="dashboard-main container-fluid">
    <div class="row mb-4 pt-4 block-topbar">
        <div
            class="col-12 d-sm-flex align-items-center justify-content-between"
        >
            <div class="breadcrumb-main">
                <ul class="breadcrumb">
                    <li><a href="./">Tariff</a></li>
                    <li class="skip-arrow">Plant Association</li>
                </ul>
            </div>
        </div>
    </div>
    <div class="contents-main">
        <div class="row block-topbar filter-row">
            <!-- align-items-center justify-content-between -->
            <div class="col-md-auto d-sm-flex">
                <label class="show-entries-list">
                    Show
                    <!-- (change)="changePageSize()" -->
                    <!-- [formControl]="pageSizeControl" -->
                    <select
                        [formControl]="pageSizeControl"
                        class="form-control form-control-sm"
                        (change)="changePageSize()"
                    >
                        <option
                            *ngFor="let limit of pageRecordOptions"
                            [value]="limit.value"
                        >
                            {{ limit.label }}
                        </option>
                    </select>
                    Entries
                </label>
            </div>
            <div class="col-md-2 col-sm-4">
                <div class="form-group role">
                    <ng-select (change)="selectCompanyName($event)" placeholder="Company Name">
                        <ng-option *ngFor="let Item of companyList" [value]="Item.id">{{Item.name}}</ng-option>
                    </ng-select>  
                </div>
            </div>
            <div class="col-md-2 col-sm-4">
                <div class="form-group role">
                    <ng-select (change)="selectTariffType($event)" placeholder="Tariff Type">
                        <ng-option *ngFor="let Item of tariffTypeList" [value]="Item.id">{{Item.name}}</ng-option>
                    </ng-select> 
                </div>
            </div>
            <div class="col-md-2 col-sm-4">
                <div class="form-group role">
                    <ng-select (change)="selectPlantName($event)" placeholder="Plants">
                        <ng-option *ngFor="let Item of plantList" [value]="Item.station_id">{{Item.plant_name}}</ng-option>
                    </ng-select> 
                </div>
            </div>
            <div class="col-md-auto col-btn">
                <button
                type="button"
                class="btn btn-orange btn-sm btn-label waves-effect waves-light" (click)="addTariffPopUp(content)">
                <i class="ri-add-line label-icon align-middle fs-16 me-2"></i>
                Add New
            </button>
            </div>
        </div>
        
        <div class="table-wrap">
            <div class="table-main">
                <table class="table-data">
                    <thead>
                        <tr>
                            <th scope="col">Sr. No</th>
                            <th scope="col">Company Name</th>
                            <th scope="col">Tariff Type</th>
                            <th scope="col">Plants</th>
                            <th
                                scope="col"
                                style="width: 80px; text-align: center"
                            >
                                Actions
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of tariffList; let i = index">
                            <td>{{ item.srNo }}</td>
                            <td>{{ item.tariff_company }}</td>
                            <td>{{ item.tariff_type }}</td>
                            <td>{{ item.plant }}</td>

                            <td style="width: 40px; text-align: center">
                                <div class="d-flex justify-content-center">
                                    <button
                                        type="button"
                                        (click)="
                                            openEditPopUp(item, contentupdate)
                                        "
                                        class="dropdown-item editcategory d-inline"
                                        data-toggle="modal"
                                        data-target="#edit-category-vt"
                                    >
                                        <!-- <i class="bx bx-edit"></i> -->
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="14.433"
                                            height="18"
                                            viewBox="8 4 14.433 18"
                                        >
                                            <path
                                                d="M8.9 20.2h12.6a.9.9 0 0 1 0 1.8H8.9a.9.9 0 1 1 0-1.8ZM8 15.7l9-9 2.7 2.7-9 9H8v-2.7Zm9.9-9.9L19.7 4l2.7 2.7-1.8 1.8-2.7-2.7Z"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    </button>
                                </div>
                            </td>
                        </tr>
                        <tr *ngIf="tariffList.length === 0">
                            <td colspan="100%">
                                <p class="not-found-message p-4 text-center">No records found.</p>

                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="table-meta">
                <div class="entries">

                    <span>Total Records: {{this.total_items }}</span>               
                </div>
                <nav class="table-pagination">
                  <ul>
                      <li>
                          <div class="go-to-page d-flex align-items-center">
                              <label>Go to Page:</label>
                              <input type="number" class="form-control form-control-sm" [(ngModel)]="pageToGo" min="1" [max]="total_pages" (keydown.enter)="goToPage(pageToGo); pageToGo = null">
                              <div class="input-group-append">
                                  <button class="btn btn-primary" type="button" (click)="goToPage(pageToGo); pageToGo = null" [disabled]="pageToGo < 1 || pageToGo > total_pages">Go</button>
                              </div>
                          </div>
                      </li>                                                                                                
                      <li><a class="init-bg" id="firstPage" (click)="goToPage(1)" [class.disabled]="current_page === 1">1</a></li>
                      <li><a class="no-bg" id="prevPage" (click)="navigatePage('prev')"><i class="fa fa-chevron-left"></i></a></li>
                      <li><a class="active">{{ current_page }}</a></li>
                      <li><a class="no-bg" id="nextPage" (click)="navigatePage('next')" [class.disabled]="current_page === total_pages"><i class="fa fa-chevron-right"></i></a></li>
                      <li><a class="init-bg" id="lastPage" (click)="goToPage(total_pages)" [class.disabled]="current_page === total_pages">{{this.total_pages}}</a></li>
                  </ul>
              </nav>
                
            </div>
        </div>
    </div>
</section>

<ng-template #content let-c="close" let-d="dismiss">
    <form [formGroup]="addPlantAsscoForm" (ngSubmit)="onAddPlantTariff()">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Add Tariff Company</h4>
            <button
                type="button"
                class="btn-close"
                aria-label="Close"
                (click)="d('Cross click'); resetForm()"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13.633"
                    height="14.25"
                    viewBox="1222.583 108.567 13.633 14.25"
                >
                    <g data-name="Group 2829">
                        <g data-name="chevron-back">
                            <path
                                d="m1224 110 5.393 5.393 5.393-5.393"
                                stroke-linejoin="round"
                                stroke-linecap="round"
                                stroke-width="2"
                                stroke="#fff"
                                fill="transparent"
                                data-name="Path 5498"
                            />
                        </g>
                        <g data-name="chevron-back">
                            <path
                                d="m1224 121.393 5.393-5.393 5.392 5.393"
                                stroke-linejoin="round"
                                stroke-linecap="round"
                                stroke-width="2"
                                stroke="#fff"
                                fill="transparent"
                                data-name="Path 5498"
                            />
                        </g>
                    </g>
                </svg>
            </button>
        </div>

        <div class="modal-body">
            <div class="row">
                <div class="col-md-12 form-styles">
                    <label for="typeahead-basic">Company Name</label>
                    <ng-select formControlName="tariff_company">
                        <ng-option
                            *ngFor="let tariff of companyList"
                            [value]="tariff.id"
                            >{{ tariff.name }}</ng-option
                        >
                    </ng-select>
                    <small
                        class="text-danger"
                        *ngIf="
                            !addPlantAsscoForm.get('tariff_company').valid &&
                            addPlantAsscoForm.get('tariff_company').touched
                        "
                        >Please Enter Company Name</small
                    >
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 my-3 form-styles">
                    <label for="typeahead-basic">Tariff Type</label>
                    <ng-select formControlName="tariff_type">
                        <ng-option
                            *ngFor="let tariff of tariffTypeList"
                            [value]="tariff.id"
                            >{{ tariff.name }}</ng-option
                        >
                    </ng-select>
                    <small
                        class="text-danger"
                        *ngIf="
                            !addPlantAsscoForm.get('tariff_type').valid &&
                            addPlantAsscoForm.get('tariff_type').touched
                        "
                        >Please Enter Tariff Type</small
                    >
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 my-3">
                  <label for="typeahead-basic"> Plant</label>

                  <ng-select [items]="plantList" bindLabel="plant_name" bindValue="station_id" [multiple]="true"
                    formControlName="plant_ids" name="plantName">
                  </ng-select>
                  <small class="text-danger" *ngIf="
                                          !addPlantAsscoForm.get('plant_ids').valid &&
                                          addPlantAsscoForm.get('plant_ids').touched
                                      ">Please Enter Plant Name</small>
                </div>
              </div>
        </div>
        <div class="modal-footer no-bg">
            <button
                type="button"
                class="btn btn-outline-dark"
                aria-label="Close"
                (click)="d('Cross click'); resetForm()"
            >
                Cancel
            </button>
            <button
                type="button"
                class="btn btn-orange"
                [disabled]="addPlantAsscoForm.invalid"
                (click)="d('Cross click')"
                type="submit"
            >
                Save
            </button>
        </div>
    </form>
</ng-template>

<ng-template #contentupdate let-c="close" let-d="dismiss">
    <!-- (ngSubmit)="cardDetail(payment,content)" #payment="ngForm" -->
    <form [formGroup]="editPlantAsscoForm" (ngSubmit)="onUpdatePlantTariff()">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">
                Edit Tariff Company
            </h4>
            <button
                type="button"
                class="btn-close"
                aria-label="Close"
                (click)="d('Cross click'); resetForm()"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13.633"
                    height="14.25"
                    viewBox="1222.583 108.567 13.633 14.25"
                >
                    <g data-name="Group 2829">
                        <g data-name="chevron-back">
                            <path
                                d="m1224 110 5.393 5.393 5.393-5.393"
                                stroke-linejoin="round"
                                stroke-linecap="round"
                                stroke-width="2"
                                stroke="#fff"
                                fill="transparent"
                                data-name="Path 5498"
                            />
                        </g>
                        <g data-name="chevron-back">
                            <path
                                d="m1224 121.393 5.393-5.393 5.392 5.393"
                                stroke-linejoin="round"
                                stroke-linecap="round"
                                stroke-width="2"
                                stroke="#fff"
                                fill="transparent"
                                data-name="Path 5498"
                            />
                        </g>
                    </g>
                </svg>
            </button>
        </div>

        <div class="modal-body">
            <div class="row">
                <div class="col-md-12 form-styles">
                    <label for="typeahead-basic">Company Name</label>
                    <ng-select formControlName="tariff_company">
                        <ng-option
                            *ngFor="let tariff of companyList"
                            [value]="tariff.id"
                            >{{ tariff.name }}</ng-option
                        >
                    </ng-select>
                    <small
                        class="text-danger"
                        *ngIf="
                            !editPlantAsscoForm.get('tariff_company').valid &&
                            editPlantAsscoForm.get('tariff_company').touched
                        "
                        >Please Enter Company Name</small
                    >
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 my-3 form-styles">
                    <label for="typeahead-basic">Tariff Type</label>
                    <ng-select formControlName="tariff_type">
                        <ng-option
                            *ngFor="let tariff of tariffTypeList"
                            [value]="tariff.id"
                            >{{ tariff.name }}</ng-option
                        >
                    </ng-select>
                    <small
                        class="text-danger"
                        *ngIf="
                            !editPlantAsscoForm.get('tariff_type').valid &&
                            editPlantAsscoForm.get('tariff_type').touched
                        "
                        >Please Enter Tariff Type</small
                    >
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 my-3">
                  <label for="typeahead-basic"> Plant</label>

                  <input
                        id="typeahead-basic"
                        type="text"
                        class="form-control"
                        formControlName="plant_ids"
                        maxlength="50"
                        readonly
                    />
                  <small class="text-danger" *ngIf="
                                          !editPlantAsscoForm.get('plant_ids').valid &&
                                          editPlantAsscoForm.get('plant_ids').touched
                                      ">Please Enter Plant Name</small>
                </div>
              </div>
        </div>
        <div class="modal-footer no-bg">
            <button
                type="button"
                class="btn btn-outline-dark"
                aria-label="Close"
                (click)="d('Cross click'); resetForm()"
            >
                Cancel
            </button>
            <button
                type="button"
                class="btn btn-orange"
                [disabled]="editPlantAsscoForm.invalid"
                (click)="d('Cross click')"
                type="submit"
            >
                Save
            </button>
        </div>
    </form>
</ng-template>
