import { Injectable } from '@angular/core';
import * as Rx from 'rxjs';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';

@Injectable({
  providedIn: 'root'
})
export class NotificationSocketService {

  public socket$: WebSocketSubject<any> | undefined;
  private connectionState$: Rx.BehaviorSubject<boolean> = new Rx.BehaviorSubject<boolean>(false);
  public notifications$: Rx.BehaviorSubject<any[]> = new Rx.BehaviorSubject<any[]>([]);

  
  public ticket_id:number
  public user_id: number

  public getConnectionState() {
    return this.connectionState$.asObservable();
  }

  public getNotifications(): Rx.Observable<any[]> {
    return this.notifications$.asObservable();
  }


  public connect() {
    // const url = `wss://api.zerocarbon.com.pk/comments/${this.ticket_id}/?user_id=${this.user_id}`; 
    const url = `wss://api.zerocarbon.com.pk/notify/?user_id=${this.user_id}`; 

    if (!this.socket$ || this.socket$.closed) {
      this.socket$ = this.create(url);
    }

    return this.socket$;
  }

  private create(url: string): WebSocketSubject<any> {
    this.socket$ = webSocket(url);


    this.socket$.subscribe(
      (message) => {
        this.connectionState$.next(true);
        console.log('Notification Socket Connected!')

        if (message && message['notification_data'] && message['notification_data']) {
          this.notifications$.next(message['notification_data']);
        }

      },
      (error) => {
        this.connectionState$.next(false);
        console.error('WebSocket error:', error);
      },
      () => {
        this.connectionState$.next(false);
        console.log('WebSocket connection closed');
      }
    );

    return this.socket$;
  }

  public sendMessage(message: any): void {
    if (this.socket$ && !this.socket$.closed) {
      this.socket$.next(message);
    } else {
      console.warn('Cannot send marked as read: WebSocket is not connected.');
    }
  }

  
  public disconnect() {
    if (this.socket$ && !this.socket$.closed) {
      this.socket$.complete();
      console.log('Web Socket disconnected');
    }
  }
}
