import { UserAdd } from '@/Interface/addUser';
import { ChangePassword } from '@/Interface/changePassword';
import { AccountType } from '@/Interface/userType';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from '@services/app.service';
import { UtilityService } from '@services/utility.service';
import { DateTime } from 'luxon';
import Swal from 'sweetalert2';
import {UserProfile} from '@/Interface/userProfile'
import { io } from 'socket.io-client';


@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
    getList: any;
    userType: string;
    userList  = new UserAdd();
    public user;
    visible: boolean = false;
    changePass = new ChangePassword();
    newPasswordVisible: boolean = false;
    confPass: string;
    confirmPasswordVisible : boolean = false;
    emailAlreadyExists:boolean = false;
    userProfileObj : UserProfile;
    imageUploadedLocaly : boolean = false;

    constructor(
        private appService: AppService,
        private modalService: NgbModal,
        private _utiltiySevice: UtilityService,
        private _router:Router
    ) { }

    ngOnInit(): void {
        this.user = this.appService.loginUser;
        this.GetuserProfile();
        this.imageUploadedLocaly = false;
    }

    logout() {
        this.appService.logout();
    }

    formatDate(date) {
        return DateTime.fromISO(date).toFormat('dd LLL yyyy');
    }

    showChnagePassword(cp: any) {
        this.modalService.open(cp);
    }
    userProfile(cp1: any) {
        this.modalService.open(cp1);
    }

    GetuserProfile(){
      //debugger;
      this._utiltiySevice.getUserProfileAPI().subscribe((res)=>{
       if(res){
         if(res.message === 'Success.'){
          //debugger;
           this.userProfileObj = res.data;
           //localStorage.setItem("profilePic", this.userProfileObj.profile_picture);
           this.appService.updateUserProfilePic(this.userProfileObj.profile_picture);
         }else{
          this.userProfileObj = new UserProfile();
       }}
      });
     }

     UpdateUserProfile()
     {
        //debugger;
        this._utiltiySevice.updateUserProfileAPI(this.userProfileObj).subscribe((res)=>{
          if(res){
            //debugger;
            if(res.message === 'Success.'){         
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: '`<h2>User Profile Updated Successfully</h2>`',
                showConfirmButton: false,
                timer: 1500
            }); 
              this.GetuserProfile();         
            }else{
             this.userProfileObj = new UserProfile();
          }}
         });
     }

     dataUriToImage(): string {
      //debugger;
      if (!this.userProfileObj.profile_picture) return ''; // Handle the case where dataUri is undefined
    
      return this.userProfileObj.profile_picture; // You may need to specify the correct image format (e.g., png, jpeg)
    }

    convertToBase64(file: File) {
      const reader = new FileReader();    
      reader.onload = () => {
        this.userProfileObj.profile_picture = reader.result as string;
        this.imageUploadedLocaly = true;
      };    
      reader.readAsDataURL(file);
    }

    selectedFile: File;
    handleFileInput(event: any) {
      this.selectedFile = event.target.files[0];
      this.convertToBase64(this.selectedFile);
    }
  
    changePasswordsubmit() {
        this.getList = this._utiltiySevice.decodeUserCode();

        this.userType = this.getList.username;
        if (this.confPass !== this.changePass.new_password) {
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: '`<h2>New password and confirm password not match</h2>`',
                showConfirmButton: false,
                timer: 1500
            });
            return false;
        }

        // if (this.confPass !== this.changePass.newPassword) {
        //   Alerts.showErrorMessage("New password and confirm password not match");
        //   return false;
        // } else {
        this.changePass.username = this.userType;
        this.changePass.current_password = this.changePass.current_password;
        this.changePass.new_password = this.changePass.new_password;
        this.changePass.confirm_password = this.changePass.new_password;
        this._utiltiySevice.chanePassword(this.changePass).subscribe((res) => {
            if (res.message === 'Password Changed.') {
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: '`<h2>Your Password is updated Successfully</h2>`',
                    showConfirmButton: false,
                    timer: 1500
                });
                this.changePass.current_password = ''
                this.changePass.new_password = ''
                this.changePass.confirm_password = ''
            }else{
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: '`<h2> Old Password is incorrect. Please contact system administration.</h2>`',
                   

                    showConfirmButton: false,
                    timer: 1500
                }); 

                // this.modalService.close();
            }
        });

        //   this.changePass.userId = userID;
        //   this._complainService.confirmpassService(this.changePass).subscribe((res) => {
        //     if (res.data.result === null) {
        //       var messageShow = res.data.message;
        //       Alerts.showErrorMessage(messageShow);
        //     } else {
        //       Alerts.showSuccessMessage("Password Updated Successfully");
        //       setTimeout(() => {
        //         this.modalService.dismissAll();
        //       }, 500);
        //     }
        //   });
    }

    
    confirmPasswordClick() {
        const input = document.getElementById('confirmPasswordid') as HTMLInputElement | null;
        const value = input.type;
        if (value === 'password') {
          input.type = 'text';
          this.confirmPasswordVisible=true;
        } else {
          input.type = 'password';
          this.confirmPasswordVisible=false;
        }
    
      }


      newPasswordClick() {
        const input = document.getElementById('newPasswordid') as HTMLInputElement | null;
        const value = input.type;
        if (value === 'password') {
          input.type = 'text';
          this.newPasswordVisible=true;
        } else {
          input.type = 'password';
          this.newPasswordVisible=false;
        }    
      }

      fieldsClick() {
        const input = document.getElementById('oldpassword') as HTMLInputElement | null;
        const value = input.type;
        if (value === 'password') {
          input.type = 'text';
          this.visible=true;
        } else {
          input.type = 'password';
          this.visible=false;
        }    
      }      
}

      // checkEmailExistence() {
      //    this._utiltiySevice.checkEmailExistence(this.userList.email)
      //     .subscribe(
      //       response => {
      //         if (response.emailExists) {
      //           this.emailAlreadyExists = true;
      //         } else {
      //           this.emailAlreadyExists = false;
      //         }
      //       },
      //       error => {
      //         console.log(error);
      //       }
      //     );
      // }

//   newPasswordClick() {
//     const input = document.getElementById('newPasswordid') as HTMLInputElement | null;
//     const value = input.type;
//     if (value === 'password') {
//       input.type = 'text';
//       this.newPasswordVisible=true;
//     } else {
//       input.type = 'password';
//       this.newPasswordVisible=false;
//     }

//   }

//   fieldsClick() {
//     const input = document.getElementById('oldpassword') as HTMLInputElement | null;
//     const value = input.type;
//     if (value === 'password') {
//       input.type = 'text';
//       this.visible=true;
//     } else {
//       input.type = 'password';
//       this.visible=false;
//     }

//   }
// }

