import {PlantsTariff, TariffTypeAndCompany, Tariffs} from '@/Interface/tariffs';
import {HttpErrorResponse} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AppService} from '@services/app.service';
import {UtilityService} from '@services/utility.service';
import {ToastrService} from 'ngx-toastr';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-plant-tariff',
    templateUrl: './plant-tariff.component.html',
    styleUrls: ['./plant-tariff.component.scss']
})
export class PlantTariffComponent implements OnInit {
    public addPlantAsscoForm: FormGroup;
    public editPlantAsscoForm: FormGroup;
    public pageSizeControl = new FormControl(10);
    pageSize: number;
    pageToGo: number = 1;
    searchElement = '';
    serialNumberArray: Array<number> = [];
    next_page: number;
    current_page: number;
    previous_page: number;
    total_pages: number;
    total_items: number;
    private latestQueryParams: any = {};
    sortColumn: string = null;
    sortOrder: 'asc' | 'desc' = null;
    searchSubject: Subject<string> = new Subject();
    public tariffTypeList: TariffTypeAndCompany[] = [];
    public companyList: TariffTypeAndCompany[] = [];
    loader: boolean = false;
    public pageRecordOptions = [
        {value: '10', label: '10'},
        {value: '25', label: '25'},
        {value: '50', label: '50'},
        {value: '100', label: '100'},
        {value: '200', label: '200'}
    ];

    public tariffList: PlantsTariff[] = [];
    public plantList: any = [];
    public selectedId: number;
    public selectedCompanyId: number;
    public selectedTypeId: number;

    constructor(
        private modalService: NgbModal,
        private toaster: ToastrService,
        private _utilityService: UtilityService,
        private appService: AppService,
        private formBuilders: FormBuilder
    ) {}

    ngOnInit(): void {
        this.getTableData();

        this.getTariffTypeList();
        this.getAllPlants();
        this.getCompanyList();

        this.searchSubject
            .pipe(debounceTime(300))
            .subscribe((searchTerm: string) => {
                this.searchElement = searchTerm;
                this.updateQueryParams({search: this.searchElement});
            });
        this.addPlantAsscoForm = this.formBuilders.group({
            tariff_company: [null, [Validators.required]],
            tariff_type: [null, [Validators.required]],
            plant_ids: [null, [Validators.required]]
        });
        this.editPlantAsscoForm = this.formBuilders.group({
            id: [null],
            tariff_company: [null, [Validators.required]],
            tariff_type: [null, [Validators.required]],
            plant_ids: [null, [Validators.required]]
        });
    }

    public selectCompanyName(selectedCompany) {
        this.updateQueryParams({tariff_company: selectedCompany});
    }
    public selectTariffType(selectedTariff) {
        this.updateQueryParams({tariff_type: selectedTariff});
    }
    public selectPlantName(selectedPlant) {
        this.updateQueryParams({plant_id: selectedPlant});
    }
    resetForm() {
        this.addPlantAsscoForm.reset();
        this.editPlantAsscoForm.reset();
    }
    openEditPopUp(item: PlantsTariff, contentupdate: any) {
        this.selectedId = item.id;
        this.selectedCompanyId = item.tariff_company_id;
        this.selectedTypeId = item.tariff_type_id;
        this.getCompanyList();
        this.getTariffTypeList();
        this.editPlantAsscoForm.patchValue({
            id: item.id,
            tariff_company: item.tariff_company_id
                ? item.tariff_company_id
                : null,
            tariff_type: item.tariff_type_id ? item.tariff_type_id : null,
            plant_ids: item.plant
        });
        console.log('edit form:', this.editPlantAsscoForm.value);
        this.modalService.open(contentupdate);
    }

    addTariffPopUp(content: any) {
        this.modalService.open(content);
        this.getTariffTypeList();
        this.getAllPlants();
        this.getCompanyList();
    }

    onAddPlantTariff(): void {
        if (this.addPlantAsscoForm.valid) {
            const formData: TariffTypeAndCompany = this.addPlantAsscoForm.value;
            this.appService.addPlantTariff(formData).subscribe(
                (response) => {
                    if (response && response.status === 201) {
                        this.getTableData();
                        this.addPlantAsscoForm.reset();
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: response.message,
                            showConfirmButton: false,
                            timer: 1500
                        });
                    } else {
                        this.toaster.error(response.message);
                        this.addPlantAsscoForm.reset();
                    }
                },
                (error: HttpErrorResponse) => {
                    if (error.status === 400) {
                        this.toaster.error(error.error.message);
                        this.addPlantAsscoForm.reset();
                    }
                }
            );
        }
    }

    onUpdatePlantTariff(): void {
        if (this.editPlantAsscoForm.valid) {
            Swal.fire({
                title: 'Are you sure you want to update this record?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
            }).then((result) => {
                if (result.isConfirmed) {
                    const formData: PlantsTariff =
                        this.editPlantAsscoForm.value;
                    delete formData['plant_ids'];
                    console.log(formData);

                    this.appService
                        .editPlantTariff(formData)
                        .subscribe((response) => {
                            if (response && response.status === 200) {
                                this.getTableData();
                                this.editPlantAsscoForm.reset();
                                Swal.fire({
                                    position: 'center',
                                    icon: 'success',
                                    title: response.message,
                                    showConfirmButton: false,
                                    timer: 1500
                                });
                            } else {
                                this.toaster.error(response.message);
                                this.editPlantAsscoForm.reset();
                            }
                        }
                        ,(error: HttpErrorResponse) => {
                          if (error.status === 400) {
                            this.toaster.error(error.error.message);
                            this.addPlantAsscoForm.reset();
                          } 
                        }
                      );
                }
            });
        }
    }

    private updateQueryParams(paramsToUpdate: any): void {
        this.latestQueryParams = {
            ...this.latestQueryParams,
            ...paramsToUpdate
        };
        console.log('Updated queryParams:', this.latestQueryParams);

        if (this.latestQueryParams.search === '') {
            this.latestQueryParams.search = null;
        }
        if (this.latestQueryParams.page === 1) {
            this.latestQueryParams.page = null;
        }

        if (this.latestQueryParams.size === 10) {
            this.latestQueryParams.size = null;
        }
        if (this.latestQueryParams.sort === null) {
            delete this.latestQueryParams.sort;
        }

        if (this.latestQueryParams.column === null) {
            delete this.latestQueryParams.column;
        }
        if (this.latestQueryParams.tariff_company === null) {
            delete this.latestQueryParams.tariff_company;
        }
        if (this.latestQueryParams.tariff_type === null) {
            delete this.latestQueryParams.tariff_type;
        }
        if (this.latestQueryParams.plant_id === null) {
            delete this.latestQueryParams.plant_id;
        }

        if (
            this.latestQueryParams.page === 1 ||
            (this.latestQueryParams.page &&
                (this.latestQueryParams.search ||
                    this.latestQueryParams.tariff_company ||
                    this.latestQueryParams.tariff_type ||
                    this.latestQueryParams.plant_id))
        ) {
            if (this.latestQueryParams.page > 1) {
                this.latestQueryParams.page = paramsToUpdate.page;
                if (this.latestQueryParams.size > 10) {
                    this.latestQueryParams.size = paramsToUpdate.size;
                }
            } else {
                this.latestQueryParams.page = null;
            }
        }
        // if (!this.next_page && (paramsToUpdate.size || paramsToUpdate.search)) {
        //     this.latestQueryParams = {
        //         size: paramsToUpdate.size || null,
        //         search: paramsToUpdate.search || null
        //     };
        // }
        this.getTableData();
    }

    private getTableData(): void {
        const name = this.latestQueryParams['search'] || this.searchElement;
        const page =
            this.latestQueryParams['page'] && this.next_page !== null
                ? +this.latestQueryParams['page']
                : 1;
        const size = this.latestQueryParams['size']
            ? +this.latestQueryParams['size']
            : 10;
        const sort = this.latestQueryParams['sort'] || null;
        const column = this.latestQueryParams['column'] || null;
        const tariff_company = this.latestQueryParams['tariff_company'] || null;
        const tariff_type = this.latestQueryParams['tariff_type'] || null;
        const plant_id = this.latestQueryParams['plant_id'] || null;
        this.getAllPlantTariff(
            name,
            page,
            size,
            sort,
            column,
            tariff_company,
            tariff_type,
            plant_id
        );
    }

    getAllPlantTariff(
        name: string,
        page: number,
        size: number,
        sort: string | null,
        column: string | null,
        tariff_company: number | null,
        tariff_type: number | null,
        plant_id: number | null
    ): void {
        this.loader = true;
        this.tariffList = [];
        this.serialNumberArray = [];
        this._utilityService
            .receiveAllPlantTariffs(
                name,
                page,
                size,
                sort,
                column,
                tariff_company,
                tariff_type,
                plant_id
            )
            .subscribe((res: any) => {
                if (res && res['status'] === 200) {
                    this.tariffList = res.data;
                    this.tariffList = res.data.map(
                        (tariff: any, index: number) => ({
                            ...tariff,
                            srNo:
                                (res.pagination.current_page - 1) * size +
                                index +
                                1
                        })
                    );
                    console.log(this.tariffList);
                    this.current_page = res.pagination.current_page;
                    this.next_page = res.pagination.next_page;
                    this.previous_page = res.pagination.previous_page;
                    this.total_pages = res.pagination.total_pages;
                    this.total_items = res.pagination.total_records;
                    this.enablePagination();
                }
                this.loader = false;
            });
    }
    public searchData(event: Event): void {
        this.searchElement = (event.target as HTMLInputElement)?.value || '';
        console.log('search Value', this.searchElement);
        this.searchSubject.next(this.searchElement);
    }

    goToPage(page: number): void {
        console.log('move to page', page);
        if (page >= 1 && page <= this.total_pages) {
            this.updateQueryParams({page: page});
        }
    }

    navigatePage(direction: string): void {
        if (direction === 'prev' && this.current_page > 1) {
            this.goToPage(this.current_page - 1);
        } else if (direction === 'next' && this.next_page !== null) {
            this.goToPage(this.current_page + 1);
        }
    }

    // Add a method to navigate to the entered page
    navigateToPage(): void {
        if (this.pageToGo >= 1 && this.pageToGo <= this.total_pages) {
            this.goToPage(this.pageToGo);
        }
    }

    public changePageSize(): void {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.pageSize = this.pageSizeControl.value!;
        this.updateQueryParams({size: this.pageSize});
    }

    enablePagination() {
        const linkElement = document.getElementById('prevPage');

        if (this.previous_page === null) {
            linkElement.setAttribute('disabled', 'true');
            linkElement.style.pointerEvents = 'none';
            linkElement.style.color = 'gray';
            linkElement.addEventListener('click', (event) => {
                event.preventDefault();
            });
        } else {
            linkElement.removeAttribute('disabled');
            linkElement.style.pointerEvents = 'auto';
            linkElement.style.color = '';
            linkElement.removeEventListener('click', (event) => {
                event.preventDefault();
            });
        }
    }
    getTariffTypeList() {
        this._utilityService.receivedropdownTraiffTypes().subscribe((res) => {
            if (res && res.status === 200) {
                if (res.data !== null) {
                    this.tariffTypeList = res.data;
                }
            }
        });
    }
    getAllPlants() {
        this.appService.PlantsdetailsAllApi().subscribe((res) => {
            if (res && res.status === 200) {
                if (res.data !== null) {
                    this.plantList = res.data;
                }
            }
        });
    }
    getCompanyList() {
        this.appService.receivedropdownCompanyTariff().subscribe((res) => {
            if (res && res.status === 200) {
                if (res.data !== null) {
                    this.companyList = res.data;
                }
            }
        });
    }
}
