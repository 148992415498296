import {
    Component,
    OnInit,
    ViewChild,
    OnDestroy,
    EventEmitter,
    Output,
    DoCheck,
    AfterViewInit,
    ChangeDetectorRef
} from '@angular/core';

import {HttpClient} from '@angular/common/http';
import {AppService} from '@services/app.service';
import {online} from '@/Interface/status';
import {Subject} from 'rxjs';

import {Plants} from '@/Interface/Register';
import {PlantsNew} from '@/Interface/Register';
import {Pdetails, PlantList, PlantStatusDDL} from '@/Interface/plants';
import {ActivatedRoute, Router} from '@angular/router';
import {UtilityService} from '@services/utility.service';
import * as Highcharts from 'highcharts';
import { PlantDetailComponent } from '@pages/plant-detail/plant-detail.component';
import { FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'app-blank',
    templateUrl: './blank.component.html',
    styleUrls: ['./blank.component.scss']
})
export class BlankComponent implements DoCheck, AfterViewInit{
    showPlantDetail: boolean = false;
    plantList: PlantList;
    sortColumn: string = 'plantName'; // Default sorting column
    sortOrder: 'asc' | 'desc' = null; // Default sorting order
    sortOrderPlantName: 'asc' | 'desc' | null = null;
    sortOrderCapacity: 'asc' | 'desc' | null = null;
    // detail: Plants[] = [];
    detail: PlantsNew[] = [];
    plantStatuslst : PlantStatusDDL[] = [];
    plantTypelst : PlantStatusDDL[] = [];
    searchElement = '';
    serialNumberArray: Array<number> = [];
    
    next_page: number;    
    current_page: number;   
    filtered_page: number;   
    previous_page : number;
    last_page : number;
    total_pages : number;
    total_items:number;
    pageSize:number
    pageToGo: number = 1;
    public pageSizeControl = new FormControl(10);
    searchSubject: Subject<string> = new Subject();
    public pageRecordOptions = [
        { value: '10', label: '10' },
        { value: '25', label: '25' },
        { value: '50', label: '50' },
        // { value: '100', label: '100' }
      ];


    pdetail: Pdetails;
    myData: [] = [];

    Highcharts: typeof Highcharts = Highcharts;

    NormalizedPwowerGraph : any[] = [];
    AvailabilityPoweGraph : any[] = [];
    stationCdeList : any[] = [];
    plantNamesList : any[] =[];
    selectedPlantName:string
    normalizegraphList : any[] = [];
    NormalizedPower: any[] = [];
    AvailabilityPower: any[] = [];
    plantName: any;
    plantstatusfilter: number = 0;
    plantTypefilter: number = 0;
    state: online;
    dash: Pdetails;
    p: number = 1;
    tableLoader:boolean;
    message: boolean;
    private latestQueryParams: any = {};

    @Output() onSelected = new EventEmitter<any>();

    hasInitialized : boolean = false;

    constructor(
        private http: HttpClient,
        private appservice: AppService,
        private _router: Router,
        private activatedRoute:ActivatedRoute,
        private _utilityService: UtilityService,
        private _changeDector: ChangeDetectorRef,
        private _datePipe :DatePipe
      

    ) {}
    ngOnInit(): void {
      // debugger
        // this.tableLoader = true;
        this.fillDropDown();
        // this.plants();
        this.getTableData();
        this.Status();
        this.dashboard();
        this.getdashboardDetails();

        this._utilityService.setUrlactve.subscribe((res)=>{
            this.message = res;
            this.showPlantDetail =  this.message;
         });        
         this.searchSubject.pipe(debounceTime(300)).subscribe((searchTerm: string) => {
            this.searchElement = searchTerm;
            this.updateQueryParams({ search: this.searchElement });
          }); 

        //  this.tableLoader = false;
        this.showPlantDetail = false;  
        
        this.hasInitialized = false; 
        console.log(' this.hasInitialized', this.hasInitialized)
         
    }
    // front-end sorting
    onSort(event: any): void {
      const sortBy = event.sorts[0];
      console.log('sortBy',sortBy)
      console.log('event',event)
      // Assuming your data is an array of objects (this.ticketList), use Array.prototype.sort()
      this.detail.sort((a, b) => {
        const valueA = a.hasOwnProperty(sortBy.prop) ? a[sortBy.prop] : null;
        const valueB = b.hasOwnProperty(sortBy.prop) ? b[sortBy.prop] : null;
  
          if (typeof valueA === 'number' && typeof valueB === 'number') {
              return sortBy.dir === 'asc' ? valueA - valueB : valueB - valueA;
          } else if (typeof valueA === 'string' && typeof valueB === 'string') {
              return sortBy.dir === 'asc' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
          } else {
              return String(valueA).localeCompare(String(valueB));
          }
      });
  }


    ngDoCheck()
    {
      // this.getTableData();

         if(this.hasInitialized)
        {
            return;            
        }

        // debugger;

        if(this.stationCdeList.length > 0)
        {
            this.LoadNormalizedGraph();
            this.LoadAvailabilityGraph();            
            this.hasInitialized = true;
        }

    }

    ngAfterViewInit(): void {
        //debugger;
    }
//     private updateQueryParams(paramsToUpdate: any): void {
//       const queryParams = {
//           ...this.activatedRoute.snapshot.queryParams,
//           ...paramsToUpdate
//       };
//       console.log('Updated queryParams:', queryParams);

//       if (queryParams.search === '') {
//           queryParams.search = null;
//       }
//       if (queryParams.page === 1) {
//           queryParams.page = null;
//       }
      
//       if (queryParams.size === 10) {
//           queryParams.size = null;
//       }
//       this._router
//           .navigate([], {
//               relativeTo: this.activatedRoute,
//               queryParams: queryParams,
//               queryParamsHandling: 'merge',
//           })
//           .then(() => {
//               this.getTableData();
//           });
//   }

//   private getTableData(): void {
//     const name =
//         this.activatedRoute.snapshot.queryParams['search'] ||
//         this.searchElement;
//     console.log('name search', name);
//     this.current_page = this.activatedRoute.snapshot.queryParams['page']
//         ? +this.activatedRoute.snapshot.queryParams['page']
//         : 1;
//     console.log('current Page', this.current_page);
//     this.pageSize = this.activatedRoute.snapshot.queryParams['size']
//         ? +this.activatedRoute.snapshot.queryParams['size']
//         : 10;
//     this.getAllPlants(name, this.current_page, this.pageSize);
// }

private updateQueryParams(paramsToUpdate: any): void {
  this.latestQueryParams = {
      ...this.latestQueryParams,
      ...paramsToUpdate
  };

  console.log('Updated queryParams:', this.latestQueryParams);
  if(this.latestQueryParams.page && (this.latestQueryParams.status && this.latestQueryParams.type )){
    this.latestQueryParams.page = paramsToUpdate.page
  }

  if (this.latestQueryParams.search === '') {
      this.latestQueryParams.search = null;
  }

  if (this.latestQueryParams.page === 1) {
      this.latestQueryParams.page = null;
  }

  if (this.latestQueryParams.size === 10) {
      this.latestQueryParams.size = null;
  }

  if (this.latestQueryParams.sort === null || this.latestQueryParams.column === null) {
      delete this.latestQueryParams.sort;
      delete this.latestQueryParams.column;
  }
  if (this.latestQueryParams.type === null) {
    delete this.latestQueryParams.type;
}

if (this.latestQueryParams.status === null) {
    delete this.latestQueryParams.status;
}

  this.getTableData();
}

private getTableData(): void {
  const name = this.latestQueryParams['search'] || this.searchElement;

  const page = this.latestQueryParams['page'] ? +this.latestQueryParams['page'] : 1;

  const size = this.latestQueryParams['size'] ? +this.latestQueryParams['size'] : 10;

  const sort = this.latestQueryParams['sort'] || null;
  const column = this.latestQueryParams['column'] || null;

  const type = this.latestQueryParams['type'] || null;
  const status = this.latestQueryParams['status'] || null;

  this.getAllPlants(name, page, size, sort, column, type, status);
}


getAllPlants(name: string, page: number, size: number,sort: string | null, column: string | null,type: string | null, status: string | null): void {
  this.tableLoader = true;
  this.detail = [];
  this.stationCdeList = [];
  this.serialNumberArray = [];
  this.appservice
    .getAllPlantsdetails(name, page, size,sort,column, type, status)
    .subscribe((res: any) => {
      if (res && res['status'] === 200) {
        // this.detail = res.data
        this.detail = res.data.map((plant: any) => {
          plant.capacity = plant.capacity.toFixed(2)
          plant.specificYield = (plant.specificYield / 1000 / 1000).toFixed(2)
          plant.currentPower = plant.currentPower.toFixed(2)
          plant.energyToday = plant.energyToday.toFixed(2)
          return plant;
        });
        console.log('Plant List with units: ',this.detail)

        this.stationCdeList = res.stationCodes;
        this.plantNamesList = res.plantName;
        this.current_page = res.current_page;
        this.next_page = res.next_page;
        this.previous_page = res.previous_page;
        this.total_pages = res.total_pages;
        this.total_items = res.total_records;
        this.enablePagination();
        this.hasInitialized = false;
      }
      this.tableLoader = false;
    });
}
//  Sorting as query params (backend)
sortPlants(column: string): void {
  if (this.sortColumn === column) {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
  } else {
      this.sortColumn = column;
      this.sortOrder = 'asc';
  }

  let sortValue: string | null = null;
  let columnName: string;

  if (this.sortOrder === 'asc') {
      sortValue = '0';
  } else if (this.sortOrder === 'desc') {
      sortValue = '1';
  }

  if (column === 'plantName') {
      columnName = 'stationName';
      this.sortOrderPlantName = this.sortOrder;
      this.sortOrderCapacity = null;
  } else if (column === 'capacity') {
      columnName = column;
      this.sortOrderCapacity = this.sortOrder;
      this.sortOrderPlantName = null;
  }

  this.updateQueryParams({ sort: sortValue, column: columnName });
}


public searchData(event: Event): void {
    this.searchElement = (event.target as HTMLInputElement)?.value || '';
    console.log('search Value', this.searchElement);
    // this.updateQueryParams({search: this.searchElement});    
    this.searchSubject.next(this.searchElement);

}

goToPage(page: number): void {
    console.log('move to page2222', page);
    if (page >= 1 && page <= this.total_pages) {
        this.updateQueryParams({page:page});
    }
}

navigatePage(direction: string): void {
  console.log('move to page', direction);

    if (direction === 'prev' && this.current_page > 1) {
        this.goToPage(this.current_page - 1);
    } else if (direction === 'next' && this.next_page !== null) {
        this.goToPage(this.current_page + 1);
    }
}
// Add a method to navigate to the entered page
navigateToPage(): void {
  if (this.pageToGo >= 1 && this.pageToGo <= this.total_pages) {
      this.goToPage(this.pageToGo);
  }
}

public changePageSize(): void {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.pageSize = this.pageSizeControl.value!;
    this.updateQueryParams({size:this.pageSize})
  }

    plants() {
        this.appservice.Plantsdetails().subscribe((res) => {
            if(res){
                this.detail = res.data;
                console.log('this.details',this.detail)
                this.stationCdeList = res.stationCodes;
                this.plantNamesList = res.plantName
                this.current_page = res.current_page;
                if(res.next_page == null)
                {
                    this.next_page = res.last_page;
                }
                else{
                    this.next_page = res.next_page;
                }
                this.previous_page = res.previous_page;
                this.last_page = res.last_page;
                this.total_pages = res.total_pages;
                this.enablePagination();
            }
           
        });
    }

    // getAvailabilityChartOption(AvailabilityChartList: any) {
    //     var NormalizedGraph = {
    //       chart: {
    //         type: 'area',
    //         margin: [0, 0, 0, 0], // Set the margin to [top, right, bottom, left]
    //       },
    //       title: {
    //         text: null,
    //       },
    //       xAxis: {
    //         title: {
    //           text: null,
    //         },
    //         labels: {
    //           enabled: false,
    //         },
    //         visible: false, // Hide the xAxis
    //       },
    //       yAxis: {
    //         labels: {
    //           enabled: false,
    //         },
    //         title: {
    //           text: null,
    //         },
    //         visible: false, // Hide the yAxis
    //       },
    //       plotOptions: {
    //         area: {
    //           cursor: 'pointer',
    //           dataLabels: {
    //             enabled: false,
    //           },
    //           showInLegend: false,
    //         },
    //       },
    //       credits: {
    //         enabled: false, // Disable the Highcharts credits, which include the watermark
    //       },
    //       tooltip: {
    //         style: {
    //           fontSize: '9px', // Set the desired tooltip text size
    //         },
    //         padding: 2, // Adjust the padding to reduce spacings
    //       },
    //       series: [
    //         {
    //           name: 'Percentage',
    //           data: AvailabilityChartList,
    //           color: '#AA4A56',
    //           type: 'area', // to make bar graph again make its type: 'column'
    //           fillOpacity: 0.3,   
    //           showInLegend: false,
    //         },
    //       ],
    //     };
      
    //     return NormalizedGraph;
    // }

    getAvailabilityChartOption(AvailabilityChartList: any) {
      var NormalizedGraph = {
          chart: {
              type: 'area',
              margin: [0, 0, 0, 0], // Set the margin to [top, right, bottom, left]
          },
          title: {
              text: null,
          },
          xAxis: {
              type: 'datetime', // Specify type as datetime
              dateTimeLabelFormats: {
                  hour: '%I:%M %p', 
              },
              title: {
                  text: 'Time', // Label for x-axis
              },
          },
          yAxis: {
              labels: {
                  enabled: false,
              },
              title: {
                  text: null,
              },
              visible: false, // Hide the yAxis
          },
          plotOptions: {
              area: {
                  cursor: 'pointer',
                  dataLabels: {
                      enabled: false,
                  },
                  showInLegend: false,
              },
          },
          credits: {
              enabled: false, // Disable the Highcharts credits, which include the watermark
          },
          tooltip: {
              style: {
                  fontSize: '9px', // Set the desired tooltip text size
              },
              padding: 2,
              formatter: function () {
                  return Highcharts.dateFormat('%I:%M %p', this.x) + '<br/>' + this.y + '%';
              },
          },
          series: [
              {
                  name: 'Percentage',
                  data: AvailabilityChartList.map(data => [data.timestamp.getTime(), data.percentage]), // Map timestamp to milliseconds
                  color: '#AA4A56',
                  type: 'area', // to make bar graph again make its type: 'column'
                  fillOpacity: 0.3,   
                  showInLegend: false,
              },
          ],
      };
    
      return NormalizedGraph;
  }
  


    // getChartOption(graphValuesList) {
    //     var NormalizedGraph = {
    //       chart: {
    //         type: 'area',
    //         margin: [0, 0, 0, 0], // Set the margin to [top, right, bottom, left]
    //       },
    //       title: {
    //         text: null,
    //       },
    //       xAxis: {
    //         title: {
    //           text: null,
    //         },
    //         labels: {
    //           enabled: false,
    //         },
    //         visible: false, // Hide the xAxis
    //       },
    //       yAxis: {
    //         labels: {
    //           enabled: false,
    //         },
    //         title: {
    //           text: null,
    //         },
    //         visible: false, // Hide the yAxis
    //       },
    //       plotOptions: {
    //         area: {
    //           cursor: 'pointer',
    //           dataLabels: {
    //             enabled: false,
    //           },
    //           showInLegend: false,
    //           lineWidth: 0, // Set the border width to 0 to hide borders
    //         },
    //       },
    //       credits: {
    //         enabled: false,
    //       },
    //       tooltip: {
    //         style: {
    //           fontSize: '8px', // Set the desired tooltip text size
    //         },
    //         padding: 2, // Adjust the padding to reduce spacings
    //       },
    //       series: [
    //         {
    //           name: 'Power',
    //           data: graphValuesList,
    //           type: 'area',
    //           color: 'Orange',
    //         },
    //       ],
    //     };
      
    //     return NormalizedGraph;
    //   }

    getChartOption(graphValuesList) {
      var NormalizedGraph = {
          chart: {
              type: 'area',
              margin: [0, 0, 0, 0], // Set the margin to [top, right, bottom, left]
          },
          title: {
              text: null,
          },
          xAxis: {
              type: 'category', // Use category type for time values
              title: {
                  text: 'Time',
              },
              labels: {
                  style: {
                      fontSize: '10px', // Adjust label font size if needed
                  },
              },
          },
          yAxis: {
              labels: {
                  enabled: false,
              },
              title: {
                  text: null,
              },
              visible: false, // Hide the yAxis
          },
          plotOptions: {
              area: {
                  cursor: 'pointer',
                  dataLabels: {
                      enabled: false,
                  },
                  showInLegend: false,
                  lineWidth: 0, // Set the border width to 0 to hide borders
              },
          },
          credits: {
              enabled: false,
          },
          tooltip: {
              style: {
                  fontSize: '8px', // Set the desired tooltip text size
              },
              padding: 2, // Adjust the padding to reduce spacings
          },
          series: [
              {
                  name: 'Power',
                  data: graphValuesList,
                  type: 'area',
                  color: 'Orange',
              },
          ],
      };
  
      return NormalizedGraph;
  }
  

    fillDropDown()
    {
      this.plantStatuslst = [];
        this.plantStatuslst.push(new PlantStatusDDL(1, 'Offline'));
        this.plantStatuslst.push(new PlantStatusDDL(2, 'Faulty'));
        this.plantStatuslst.push(new PlantStatusDDL(3, 'Online'));

        this.plantTypelst.push(new PlantStatusDDL(1, 'Huawei'));
        this.plantTypelst.push(new PlantStatusDDL(2, 'GoodWe'));        
    }

    Filterplants(filter: string) {
        ////debugger;
        this.tableLoader = true;
        
        this.appservice.FilteredPlantsdetails(filter).subscribe((res) => {
            if(res){
              console.log('Filter Plant List',res.data)
                // debugger;
                // this.detail = res.data;
                this.detail = res.data.map((plant: any) => {
                  plant.capacity = plant.capacity.toFixed(2);
                  plant.specificYield = (plant.specificYield * 1000).toFixed(2);
                  plant.currentPower = plant.currentPower.toFixed(2);
                  plant.energyToday = plant.energyToday.toFixed(2);
                  return plant;
                });
                this.stationCdeList = res.stationCodes;
                this.current_page = res.current_page;
                if(res.next_page == null)
                {
                    this.next_page = res.last_page;
                }
                else{
                    this.next_page = res.next_page;
                }
                this.previous_page = res.previous_page;
                this.last_page = res.last_page;
                this.total_pages = res.total_pages;
                this.enablePagination();

                this.hasInitialized = false;                
                this.tableLoader = false;
            }
           
        });
    }

 
    enablePagination()
    {
        const linkElement = document.getElementById('prevPage');

        if (this.previous_page === null) {
            linkElement.setAttribute('disabled', 'true');
            linkElement.style.pointerEvents = 'none';
            linkElement.style.color = 'gray'; // You can set the color to indicate it's disabled
            linkElement.addEventListener('click', (event) => {
            event.preventDefault(); // Prevent the default behavior (navigation)
        });
        } else {
            linkElement.removeAttribute('disabled');
            linkElement.style.pointerEvents = 'auto'; // Enable clicking
            linkElement.style.color = ''; // Reset the color to its default
            linkElement.removeEventListener('click', (event) => {
            event.preventDefault();
        });
        }
    }

    LoadNormalizedGraph() {

        this.appservice.getNormalizePowerGraph({"codes" :this.stationCdeList}).subscribe((res) => {
            //////debugger;
            this.normalizegraphList = res.data;
            this.NormalizedPwowerGraph = [];
            // if(this.normalizegraphList.length > 0)
            // {
            //     this.normalizegraphList.forEach((item) => {
            //         this.NormalizedPwowerGraph[item.stationCode] = [];

            //         item.generation.forEach((e) => {
            //           const date = new Date(Number(e.timestamp) * 1000);     
            //                 const time = this._datePipe.transform(date, 'hh:mm a');
            //                 this.NormalizedPwowerGraph.push(
            //                     time
            //                 );
            //             this.NormalizedPwowerGraph[item.stationCode].push(e.powerGenerated);
            //         });

            //         this.NormalizedPower[item.stationCode] =this.getChartOption(this.NormalizedPwowerGraph[item.stationCode]);

            //     });
            //     this.hasInitialized = true;
            //     ////debugger;

            // }
            if(this.normalizegraphList.length > 0) {
              this.normalizegraphList.forEach((item) => {
                  const stationData = []; // Array to hold time and power data for each station
  
                  item.generation.forEach((e) => {
                      const date = new Date(Number(e.timestamp) * 1000);
                      const time = this._datePipe.transform(date, 'hh:mm a');
                      
                      // Push time and power data as an array to stationData
                      stationData.push([time, e.powerGenerated]);
                  });
  
                  // Push stationData to NormalizedPwowerGraph
                  this.NormalizedPwowerGraph[item.stationCode] = stationData;
  
                  // Generate chart options for each station
                  this.NormalizedPower[item.stationCode] = this.getChartOption(stationData);
              });
  
              this.hasInitialized = true;
          }
        });

    }
    
    LoadAvailabilityGraph() {

        this.appservice.getAvailabilityGraph({"codes" :this.stationCdeList}).subscribe((res) => {
            ////debugger;
            this.normalizegraphList = res.data;
            this.AvailabilityPoweGraph = [];
            if(this.normalizegraphList.length > 0)
            {
                this.normalizegraphList.forEach((item) => {
                    this.AvailabilityPoweGraph[item.stationCode] = [];

                    item.availabilityChartData.forEach((e) => {
                        // this.AvailabilityPoweGraph[item.stationCode].push(e.percentage);
                        // Adjust how you push data to include both timestamp and percentage
                      this.AvailabilityPoweGraph[item.stationCode].push({
                        timestamp: new Date(e.timestamp),
                        percentage: e.percentage
                    });
                    });
                    this.AvailabilityPower[item.stationCode] =this.getAvailabilityChartOption(this.AvailabilityPoweGraph[item.stationCode]);

                });
                this.hasInitialized = true;
                ////debugger;

            }
        });

    }
    
    Status() {
        this.appservice.plantStatus().subscribe((res) => {
            this.state = res.data;
        });
    }
    dashboard() {
        this.appservice.dashboard().subscribe((res) => {
            this.dash = res.data;
        });
    }

    createfilter() : string{

        var filter = "";
        if(!(this.plantName  === null || this.plantName  == undefined))
        {
            if(this.plantName !== "")
            {
                filter = filter + "search=" + this.plantName;
            }
        }
        if(this.filtered_page >= 0)
        {
            if(filter !== "")
            {
                filter = filter + '&';
            }
            filter = filter + "page=" + this.filtered_page;
        }

        if(this.plantstatusfilter > 0)
        {
            if(filter !== "")
            {
                filter = filter + '&';
            }
            filter = filter + "status=" + this.plantstatusfilter;
        }

        if(this.plantTypefilter > 0)
        {
            if(filter !== "")
            {
                filter = filter + '&';
            }
            filter = filter + "type=" + this.plantTypefilter;
        }

        return filter;
    }

    selctPlantlistStatus(sourceId) {
       //////debugger;
       console.log('select plant list status:')
       let statusQueryParam = null;
       if(sourceId  === null || sourceId  == undefined)
        {
            this.plantstatusfilter = 0;
        }
        else{
            this.plantstatusfilter = sourceId;
            statusQueryParam = sourceId; // new line added 
        }   
        // this.Filterplants(this.createfilter());
        this.updateQueryParams({ status: statusQueryParam });       
    }

    selctPlantlistType(sourceId) {
        //////debugger;
        let typeQueryParam = null;  // new line added 
        if(sourceId  === null || sourceId  == undefined)
        {
            this.plantTypefilter = 0;
        }
        else{
            this.plantTypefilter = sourceId;
            typeQueryParam = sourceId; // new line added 
        } 
        
        // this.Filterplants(this.createfilter());   // previous function
        this.updateQueryParams({ type: typeQueryParam });  // new line added 
       
     }
 


    Pagination(pageNo: number) {
        //////debugger;
        if (pageNo >= 1 && pageNo <= this.total_pages) {
            this.filtered_page = pageNo;  
            this.Filterplants(this.createfilter());          
          }
    }
  
    Search() {
        //////debugger;
        if (this.plantName == '') {
            this.ngOnInit();
        } else {
            this.Filterplants(this.createfilter());
           
            // this.detail = this.detail.filter((res) => {
            //     return res.plantName
            //         ?.toLocaleLowerCase()
            //         .match(this.plantName?.toLocaleLowerCase());
            //});
        
        }
    }

    key: string = 'capacity';
    reverse: boolean = false;
    sort(key: any) {
        this.key = key;
        this.reverse = !this.reverse;
    }

    plantDetail(item: PlantList) {
        ////debugger;
        this.showPlantDetail = true;
        var stationCode = item.stationCode;
        this.selectedPlantName = item.plantName;
        this._utilityService.updateSelectedPlantName(this.selectedPlantName);
        this._utilityService.setProduct(stationCode);
        // this._router.navigate(['/plant-detail'])
    }


    getdashboardDetails() {
        this.appservice.dashboard().subscribe((res) => {
          this.pdetail = res.data;
        });
      }
}
